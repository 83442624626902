import { useTranslation } from 'react-i18next'
import { IAudit } from '../IAudit'
import { Typography } from '@mui/material'

const ListItemTextAttributesItem = (item: IAudit) => {
    const { t } = useTranslation()
    return (
        <Typography component="span" variant="body2" color="textSecondary">
            <div>
                {'User'}: {item.UserName}
            </div>
            <div>
                {'Changes'}: {item.Changes}
            </div>
            <div>
                {'Date'}: {item.Date.replace('T', ' ').substring(0, 16)}
            </div>
        </Typography>
    )
}
export default ListItemTextAttributesItem
