import { Typography } from '@mui/material'
import { EMAILICON, PHONEICON, ROLESICON, STUDENTSICON } from '../../../../../styles/_icons'
import CustomSVG from '../../../../Common/CustomSVG/CustomSVG'
import { IUser } from '../IUser'

export const ListItemTextAttributes = (item: IUser) => {
    //const { t } = useTranslation()
    return {
        ...item,
        checked: false,
        ProfileImg: item.ProfileImg,
        primaryText: item.Name + ' ' + item.Surname1 + ' ' + item.Surname2,
        newSecondaryText: (
            <Typography component="span" variant="body2" color="textSecondary">
                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={PHONEICON} />
                    </div>
                    {item.ContactNumber}
                </div>

                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={EMAILICON} />
                    </div>
                    {item.ContactEmail}
                </div>

                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={ROLESICON} />
                    </div>
                    {item.RoleDSCR + ''}
                </div>

                {item.StudentsDSCR !== '' && (
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={STUDENTSICON} />
                        </div>
                        {item.StudentsDSCR + ''}
                    </div>
                )}
            </Typography>
        ),
    }
}

export default ListItemTextAttributes
