import { IconButton, Toolbar, Typography, styled } from '@mui/material'
import CustomAvatar from '../../CustomAvatar/CustomAvatar'
import CustomSVG from '../../CustomSVG/CustomSVG'
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../Views/Administration/User/IUser'

import { useAuth } from '../../../../contexts/AuthContext'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonActionsPanel from '../../MenuActions/CommonActionsPanel/CommonActionsPanel'

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' && prop !== 'openRight' })<{
    open?: boolean
    openRight?: boolean
}>(({ theme, open, openRight }) => ({
    flexGrow: 1,
    padding: theme.spacing(10, 0, 3, 0),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    marginRight: `- ${drawerWidth}px`,
    ...(openRight && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
    openRight?: boolean
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open' && prop !== 'openRight',
})<AppBarProps>(({ theme, open, openRight }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100 % - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(openRight && {
        width: `calc(100 % - ${drawerWidth}px)`,
        marginRight: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

interface CustomDrawerToolBarProps {
    openLeft: boolean
    openRight: boolean
    openMessage: boolean
    pageIcon: string
    pageName: string
    handleOpenLeft(prm: boolean): void
    handleOpenRight(prm: boolean): void
    handleSetPageName(prm: any): void
    handleSetPageIcon(prm: any): void
}

const CustomDrawerToolBar = (props: CustomDrawerToolBarProps) => {
    const {
        openLeft,
        openRight,
        openMessage,
        handleOpenLeft,
        handleOpenRight,
        pageIcon,
        pageName,
        handleSetPageName,
        handleSetPageIcon,
    } = props
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user } = useAuth()

    const handleDrawerOpenLeft = () => {
        handleOpenLeft(!openLeft)
    }
    const handleDrawerOpenRight = () => {
        handleOpenRight(!openRight)
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Ctrl (or Cmd) and S keys are pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 'm') {
                event.preventDefault()
                navigate('/dashboard')
            }
            if (event.key === 'Escape') {
                const lastSection = location.pathname.split('/').filter(Boolean).pop()
                if (typeof lastSection != 'undefined') {
                    event.preventDefault()
                    navigate('/dashboard')
                }
            }
        }

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown)

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <AppBar position={'fixed'} open={openLeft} openRight={openRight} color="inherit">
            <Toolbar className="eskola-toolbar" variant="regular" disableGutters={false}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpenLeft}
                    edge={false}
                    sx={{ mr: 1, ...(openLeft && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>

                <CustomSVG strSVG={pageIcon} />

                <div style={{ marginLeft: '1rem', flexGrow: 1 }}>
                    <Typography variant="h6" noWrap component="div" sx={{ userSelect: 'none' }}>
                        {t(pageName)}
                    </Typography>
                </div>

                <CommonActionsPanel menuActionType="list" />
                <div>
                    <IconButton
                        data-test="drawer-right-button"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpenRight}
                        edge={false}
                    >
                        <div className="centered-avatar">
                            <CustomAvatar
                                itemType="images/user"
                                keyName="OpUserID"
                                item={user as unknown as IUser}
                                sizeHeight={40}
                                sizeWidth={40}
                                justViewMode={true}
                                noPreview={true}
                                onClick={handleDrawerOpenRight}
                            />
                        </div>
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    )
}
export default CustomDrawerToolBar
