// import './_Default.scss'
import { ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Container } from '@mui/material'
// import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'
import CustomDrawerLeft from '../../components/Common/CustomDrawer/CustomDrawerLeft/CustomDrawerLeft'
import CustomDrawerRight from '../../components/Common/CustomDrawer/CustomDrawerRight/CustomDrawerRight'
import CustomDrawerToolBar from '../../components/Common/CustomDrawer/CustomDrawerToolBar/CustomDrawerToolBar'
import UserForm from '../../components/Views/Administration/User/Form/Regular/Main/UserForm'
import { useAuth } from '../../contexts/AuthContext'
import { Mode } from '../../components/Common/Types/CommonType'

const drawerWidth = 300

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' && prop !== 'openRight' })<{
    open?: boolean
    openRight?: boolean
}>(({ theme, open, openRight }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

interface defaultProps {
    children?: ReactNode
}

const Default = (props: defaultProps) => {
    const [openLeft, setOpenLeft] = useState(false)
    const [openRight, setOpenRight] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const [openMyProfile, setOpenMyProfile] = useState(false)

    const { user } = useAuth()
    console.log('Inside CustomDrawerMain the image is:', user?.ProfileImg)

    const { t } = useTranslation()
    const [pageName, setPageName] = useState(user!.OrganizationDSCR)
    const [pageIcon, setPageIcon] = useState<string>('')
    const { children } = props

    const [closeProfileAlert, setCloseProfileAlert] = useState(false)
    const [reRenderForm, setReRenderForm] = useState(false)
    const handleDrawerOpenLeft = () => {
        handleOpenLeft(true)
        if (openRight) {
            setOpenRight(false)
        }
    }

    const handleDrawerOpenRight = () => {
        setOpenRight(true)
        if (openLeft) {
            setOpenLeft(false)
        }
    }

    const handleOpenLeft = (prm: boolean) => {
        setOpenLeft(prm)
        if (openRight) {
            setOpenRight(!prm)
        }
    }

    const handleOpenRight = (prm: boolean) => {
        setOpenRight(prm)
        if (openLeft) {
            setOpenLeft(!prm)
        }
    }

    const handleCloseNoSave = () => {
        setOpenMyProfile(false)
    }

    const handleCloseSave = () => {
        setReRenderForm(!reRenderForm)
        setCloseProfileAlert(true)
    }

    const handleOpenMyProfile = () => {
        setOpenMyProfile(true)
    }
    const handleSetPageName = (name: string) => {
        setPageName(name)
    }

    const handleSetPageIcon = (icon: string) => {
        setPageIcon(icon)
    }

    const handleCloseProfileAlert = () => {
        setCloseProfileAlert(false)
    }
    const handleOpenMyMessages = () => {
        setOpenMessage(true)
        if (openLeft) {
            setOpenLeft(false)
        }
        if (openRight) {
            setOpenRight(false)
        }
    }

    return (
        <>
            {/* <Box sx={{ display: 'flex' }}> */}
            {/* <CssBaseline /> */}

            <CustomDrawerToolBar
                handleSetPageName={handleSetPageName}
                handleSetPageIcon={handleSetPageIcon}
                pageIcon={pageIcon}
                pageName={pageName}
                handleOpenLeft={handleDrawerOpenLeft}
                handleOpenRight={handleDrawerOpenRight}
                openLeft={openLeft}
                openRight={openRight}
                openMessage={openMessage}
            />

            <CustomDrawerLeft
                handleSetPageName={handleSetPageName}
                handleSetPageIcon={handleSetPageIcon}
                handleOpenLeft={handleOpenLeft}
                openLeft={openLeft}
            />

            <CustomDrawerRight
                handleSetPageName={handleSetPageName}
                handleSetPageIcon={handleSetPageIcon}
                handleOpenRight={handleOpenRight}
                handleOpenMyProfile={handleOpenMyProfile}
                handleOpenMyMessages={handleOpenMyMessages}
                openRight={openRight}
            />

            <Main
                open={openLeft}
                openRight={openRight}
                sx={{
                    mt: '0rem',
                    backgroundColor: 'var(--grey-100)',
                    minHeight: '100vh',
                    backgroundImage: 'url("../../../../assets/backgrounds/general-background.png")',
                    backgroundSize: {
                        xs: '100% auto',
                        sm: '100% auto',
                        md: '50% auto',
                        lg: '25% auto',
                        xl: '25% auto',
                    },
                    backgroundAttachment: 'fixed',
                }}
            >
                <DrawerHeader />
                {openMyProfile && (
                    <UserForm
                        mode={Mode.DeleteEdit}
                        id={user!.OpUserID}
                        onClose={handleCloseSave}
                        onCloseNoSave={handleCloseNoSave}
                        reRender={reRenderForm}
                    />
                )}
                {children}
            </Main>
            {/* </Box > */}
        </>
    )
}
export default Default
