// src/components/Dashboard.tsx
import { Grid, Container } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemReducer, initialItemState } from '../Reducers/CommonReducer'
import { useAlert } from '../../../contexts/AlertContext'
import { useLoading } from '../../../contexts/LoadingContext'
import { Methods } from '../../../Utils/Utils'
import CommonDashboardWidget from '../CommonDashboardWidget/CommonDashboardWidget'
import api from '../../../Utils/APIService'

const CommonDashboard: React.FC = () => {
    const [state, dispatch] = useReducer(ItemReducer, initialItemState(null))
    const { setLoading, isLoading } = useLoading()
    const { setAlert } = useAlert()

    const { t } = useTranslation()

    useEffect(() => {
        const loadMore = async () => {
            try {
                if (state.ItemsLoaded || isLoading) {
                    return
                }
                if (state.displaySpinner === true) {
                    setLoading(true)
                }

                const response = await api.get('roles/' + 86 + '/dashboardselectedmenus')

                if (response.status === 401) {
                    dispatch({ type: 'SessionExpired' })
                } else {
                    //let newItems = await response.json();
                    let newItems = response.data
                    newItems = Methods.JSONUnminify(newItems)

                    if (state.page === 1 && newItems.length === 0) {
                        dispatch({ type: 'NoData' })
                    } else {
                        dispatch({ type: 'ItemsLoaded', payload: newItems })
                    }
                    dispatch({ type: 'HasMore', hasMore: false })
                }
            } catch (error: any) {
                if (error.name === 'AbortError') {
                    dispatch({ type: 'Error', payload: 'Previous request canceled' })

                    console.log('Previous request canceled')
                } else {
                    dispatch({ type: 'Error', payload: 'Error fetching options' })

                    setAlert({
                        message: error.message,
                        severity: 'error',
                        datetime: Date.now().toString(),
                    })
                }
            } finally {
                setLoading(false)
            }
        }
        loadMore()
    }, [])

    return (
        <Container>
            {state.ItemsLoaded && state.Items.length > 0 && (
                <Grid
                    display="flex"
                    flexDirection={'row'}
                    container
                    gap={2}
                    sx={{ mt: 4, p: 0 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    {state.Items.map((item: any, index) => (
                        <CommonDashboardWidget
                            index={item.MenuID}
                            key={index}
                            name={item.Name}
                            svgIcon={item.Icon}
                            route={item.Route}
                        />
                    ))}
                </Grid>
            )}
        </Container>
    )
}

export default CommonDashboard
