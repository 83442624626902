import React from 'react'
import AppNew from './AppNew'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { SocketProvider } from './contexts/SocketContext'
import { AlertProvider } from './contexts/AlertContext'
import { LoadingProvider } from './contexts/LoadingContext'
import CommonLoadingSpinner from './components/Common/CommonLoadingSpinner/CommonLoadingSpinner'
import CommonAlert from './components/Common/CommonAlert/CommonAlert'
import { AuthProvider } from './contexts/AuthContext'
import CommonErrorBoundaryWrapper from './components/Common/CommonErrorBoundary/CommonErrorBoundary'
import { ThemeProvider } from '@mui/material/styles'
import eskolaTheme from './theme/eskolaTheme'
import CssBaseline from '@mui/material/CssBaseline'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CurrentScreenProvider } from './contexts/CurrentScreenContext'

const container = document.getElementById('root')
if (!container) throw new Error('Root element not found')
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
            <SocketProvider>
                <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
                    <AlertProvider>
                        <AuthProvider>
                            <ThemeProvider theme={eskolaTheme}>
                                <CssBaseline>
                                    <CommonErrorBoundaryWrapper>
                                        <LoadingProvider>
                                            <CommonLoadingSpinner />
                                            <CommonAlert />
                                            <CurrentScreenProvider>
                                                <AppNew />
                                            </CurrentScreenProvider>
                                        </LoadingProvider>
                                    </CommonErrorBoundaryWrapper>
                                </CssBaseline>
                            </ThemeProvider>
                        </AuthProvider>
                    </AlertProvider>
                </BrowserRouter>
            </SocketProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>
)
