import { IAudit } from '../IAudit'
import ListItemTextAttributesItem from './ListItemTextAttributesItem'

const ListItemTextAttributes = (item: IAudit) => {
    return {
        ...item,
        primaryText: item.Date.replace('T', ' ').substring(0, 16),
        checked: false,
        newSecondaryText: <ListItemTextAttributesItem {...item} />,
    }
}
export default ListItemTextAttributes
