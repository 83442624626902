import { UFFormatDate } from '../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import { UFFormatTime } from '../../../../Common/UseForm/UFTimePicker/UFTimePicker'
import { IFault } from '../IFault'

export const ListItemTextAttributes = (item: IFault) => {
    //const { t } = useTranslation()
    return {
        ...item,
        checked: false,
        primaryText: UFFormatDate(item.Date) + ' ' + UFFormatTime(item.Hour),
        secondaryText: `Note: ${item.Note}`,
    }
}
export default ListItemTextAttributes
