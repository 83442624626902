import './_UFAutocomplete.scss'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { ICommon } from '../../Types/ICommon'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { AlertColor } from '@mui/material/Alert'
import { Autocomplete, Avatar, Chip, TextField } from '@mui/material'
import { Methods } from '../../../../Utils/Utils'
import { useAlert } from '../../../../contexts/AlertContext'
import api from '../../../../Utils/APIService'

interface itemListState {
    items: ICommon[]
    itemsLoading: boolean
    itemsLoaded: boolean
    parentItems: number[]
    parentItemsLoading: boolean
    parentItemsLoaded: boolean
    error: boolean
    alertOpened: boolean
    alertSeverity: AlertColor
    alertMessage: string
}
const initialItemState: itemListState = {
    items: [],
    itemsLoaded: false,
    itemsLoading: true,
    parentItems: [],
    parentItemsLoaded: false,
    parentItemsLoading: true,
    error: false,
    alertOpened: false,
    alertSeverity: 'success',
    alertMessage: '',
}

type itemListActions =
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: ICommon[] }
    | { type: 'LoadParentItems' }
    | { type: 'ParentItemsLoaded'; payload: number[] }
    | { type: 'CloseAlert' }
    | { type: 'Error'; payload: string }

const ItemReducer = (state: itemListState, action: itemListActions): itemListState => {
    switch (action.type) {
        case 'LoadItems':
            return { ...state, itemsLoading: true, itemsLoaded: false }
        case 'LoadParentItems':
            return { ...state, parentItemsLoading: true, parentItemsLoaded: false }
        case 'ItemsLoaded':
            return {
                ...state,
                items: action.payload,
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
            }
        case 'ParentItemsLoaded':
            return {
                ...state,
                parentItems: action.payload,
                parentItemsLoading: false,
                parentItemsLoaded: true,
                error: false,
            }
        case 'Error':
            return {
                ...state,
                error: true,
                itemsLoading: false,
                itemsLoaded: false,
                parentItemsLoading: false,
                parentItemsLoaded: false,
            }
        case 'CloseAlert':
            return { ...state, alertOpened: false }
        default:
            return state
    }
}

export interface IInputAutocompleteProps extends IInputProps {
    setValue: any
    parentApi: string
}

const UFAutocomplete = (props: IInputAutocompleteProps) => {
    const { name, control, url, parentApi, idColName, nameColName, setValue, required, label, chipImageName } = props

    const [state, dispatch] = useReducer(ItemReducer, initialItemState)
    const [selectedItems, setSelectedItems] = useState<number[]>(state.parentItems)
    const { setAlert } = useAlert()

    const loadParentItems = useCallback(async () => {
        const tmpURL = process.env.REACT_APP_METHODS_API + parentApi + '?ref=1'

        const response = await api.get(tmpURL)

        if (!response.data) {
            // get error message from body or default to response status

            const error = (response.data && response.data.message) || response.status
            setAlert({
                message: error,
                severity: 'error',
                datetime: Date.now().toString(),
            })
            dispatch({ type: 'Error', payload: error })
            return Promise.reject(error)
        } else {
            response.data = Methods.JSONUnminify(response.data)

            // @ts-ignore
            dispatch({
                type: 'ParentItemsLoaded',
                payload: response.data.map(function (item: any) {
                    // @ts-ignore
                    return item[idColName]
                }),
            })
        }
    }, [parentApi, idColName])

    const loadItems = useCallback(async () => {
        const tmpURL = process.env.REACT_APP_METHODS_API + '' + url + '?ref=1'

        const response = await api.get(tmpURL)

        if (!response.data) {
            // get error message from body or default to response status
            const error = (response.data && response.data.message) || response.status
            setAlert({
                message: error,
                severity: 'error',
                datetime: Date.now().toString(),
            })
            dispatch({ type: 'Error', payload: error })
            return Promise.reject(error)
        } else {
            response.data = Methods.JSONUnminify(response.data)

            dispatch({ type: 'ItemsLoaded', payload: response.data })
            if (parentApi !== '') {
                loadParentItems()
            }
        }
    }, [url, loadParentItems, parentApi])

    useEffect(() => {
        loadItems()
    }, [loadItems])

    useEffect(() => {
        setValue(name, selectedItems)
    }, [selectedItems, name, setValue])

    useEffect(() => {
        setSelectedItems(state.parentItems)
    }, [state.parentItems])

    /*const idInArray = (pIDName: string, p_id: number, selectedItems: number[]): boolean => {

        let i,
            j = selectedItems.length;

        for (i = 0; i < j; i++) {
            // @ts-ignore
            if (selectedItems[i][pIDName] === p_id) {
                return true;
            }
        }
        return false;

    }*/

    const handleOnChange = (event: object, value: any) => {
        //setSelectedItems(value);

        // @ts-ignore
        //let tmpStudents = value.map(y => state.items.filter(x => x.Name === y));

        // @ts-ignore
        setSelectedItems(value.map(x => x[idColName]))
    }

    const handleImageError = (event: any) => {
        event.target.src = '/assets/school.png'
    }
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: required,
            }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                <div style={{ width: '100%', marginTop: '1rem' }}>
                    {((parentApi !== '' && state.itemsLoaded && state.parentItemsLoaded) ||
                        (parentApi === '' && state.itemsLoaded)) && (
                        <Autocomplete
                            multiple
                            id={name}
                            onChange={handleOnChange}
                            // @ts-ignore
                            options={state.items}
                            // @ts-ignore
                            getOptionLabel={option => option[nameColName]}
                            // @ts-ignore
                            defaultValue={state.items.filter(x => state.parentItems.includes(x[idColName]))}
                            freeSolo
                            // @ts-ignore
                            renderTags={(value: readonly ICommon[], getTagProps) =>
                                value.map((option: ICommon, index: number) => {
                                    const { key, ...tagProps } = getTagProps({ index })
                                    return (
                                        <Chip
                                            // @ts-ignore
                                            key={option[idColName]}
                                            variant="outlined"
                                            // @ts-ignore
                                            label={option[nameColName]}
                                            {...tagProps} //the rest of the props but key
                                            // @ts-ignore
                                            avatar={
                                                <Avatar
                                                    // @ts-ignore
                                                    src={option[chipImageName]}
                                                    onError={handleImageError}
                                                />
                                            }
                                        />
                                    )
                                })
                            }
                            renderInput={params => (
                                <>
                                    <TextField {...params} variant="filled" label={label} placeholder={label} />
                                </>
                            )}
                        />
                    )}
                </div>
            )}
        />
    )
}

export default UFAutocomplete
