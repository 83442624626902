import './_CommonDashboardWidget.scss'
import { Grid, Paper, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { motion } from 'framer-motion'
import { Methods } from '../../../Utils/Utils'
import CustomSVG from '../CustomSVG/CustomSVG'
import { useTranslation } from 'react-i18next'

type CommonDashboardWidgetProps = {
    index: number
    name: string
    svgIcon: string
    route: string
}

const CommonDashboardWidget: React.FC<CommonDashboardWidgetProps> = ({ index, name, svgIcon, route }) => {
    const { t } = useTranslation()

    return (
        <Stack
            sx={{
                width: '162px',
                height: '162px',
                // {

                //     // xs: '47.5%', // 100px width on extra small screens
                //     // sm: '47.5%', // 200px width on small screens
                //     // md: '18.8%',   // 300px width on medium screens
                //     // lg: '18.8%',   // 400px width on large screens
                //     // xl: '25%',   // 500px width on extra large screens
                // },
            }}
        >
            <Paper
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                    padding: 2,
                    backgroundColor: Methods.generateColor(index),
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                {/* We have to wrap the MUI Link with the react-router LINK because MUI Link does a full refresh of the site, it is ok for Login but not for these ones */}
                <MuiLink component={RouterLink} to={'/' + route} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Grid display="flex" justifyContent="center" alignItems="center" container direction="column">
                        <CustomSVG strSVG={svgIcon} customFontSize="large" customSX={{ m: 1 }} />
                        <Typography
                            component="h2"
                            variant="body2"
                            gutterBottom
                            align="center"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {t(name)}
                        </Typography>
                    </Grid>
                </MuiLink>
            </Paper>
        </Stack>
    )
}

export default CommonDashboardWidget
