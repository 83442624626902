import { ICareGroup } from '../ICareGroup'

export const ListItemTextAttributes = (item: ICareGroup) => {
    //const { t } = useTranslation()
    return {
        ...item,
        checked: false,
        primaryText: item.Name,
        secondaryText: `Organization: ${item.Name} `,
    }
}
export default ListItemTextAttributes
