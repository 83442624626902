import './_UserGroupForm.scss'
import { IUserGroup } from '../IUserGroup'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tab from '@mui/material/Tab'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import UserGroupFormDetails from './Details/UserGroupFormDetails'
import { useForm } from 'react-hook-form'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import React from 'react'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import UserFormat from '../../User/List/ListItemTextAttributes'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import UserForm from '../../User/Form/Regular/Main/UserForm'
import FormLayout from '../../../../../layouts/FormLayout/FormLayout'
import CommonTabContainer from '../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../Common/CommonTabHeader/CommonTabHeader'
import { Container } from '@mui/material'

const UserGroupForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const methods = useForm<IUserGroup>({ defaultValues: props.item })
    const [tabValue, setTabValue] = useState('1')
    const { control, setValue } = methods
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [counters, setCounters] = useState<{ [key: string]: string }>({ users: '0' })
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const hanleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }
    const urls = ['usergroups/' + item.UserGroupID + '/users?count=1']
    return (
        <FormLayout
            countersURLs={urls}
            operation={operation}
            mode={mode}
            item={item}
            onClose={onClose}
            onCloseNoSave={onCloseNoSave}
            onCountersLoaded={hanleCountersLoaded}
            formLabelName="UserGroup"
            formIDColName="UserGroupID"
            formNameColName="Name"
            formAPI="usergroups"
            methods={methods}
            tabValue={tabValue}
        >
            <CommonTabHeader handleTabChange={handleTabChange}>
                <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
                {mode === Mode.DeleteEdit && parseInt(counters.users) > 0 && (
                    <Tab label={t('Users') + '(' + counters.users + ')'} value="2" icon={<AccountBoxIcon />} />
                )}
            </CommonTabHeader>

            <CommonTabContainer displayTab={'1' === tabValue}>
                <Container>
                    {' '}
                    <UserGroupFormDetails
                        item={item}
                        control={control}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            </CommonTabContainer>

            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'2' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'usergroups/' + item.UserGroupID + '/users'}
                        isVisibleNow={tabValue === '2'}
                        listItemTextAttributes={UserFormat}
                        CRUDEForm={UserForm}
                        hideAddition={true}
                        componentParams={{ avatarType: 'images/user' }}
                        CRUDEFormURL="user"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
        </FormLayout>
    )
}

export default UserGroupForm
