import React, { useEffect, useState } from 'react'

import './_CommonAlert.scss'
import { useAlert } from '../../../contexts/AlertContext'
import Alert from '@mui/material/Alert'

const CommonAlert = () => {
    const { alert, clearAlert } = useAlert()

    if (!alert) return null
    if (!alert.message) return null

    return (
        <Alert
            variant="standard"
            severity={alert.severity}
            sx={{ mb: 2 }}
            onClose={() => {
                clearAlert()
            }}
        >
            {alert.message}
        </Alert>
    )
}

export default CommonAlert
