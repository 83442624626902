import './_FaultFormDetails.scss'
import { IFault } from '../../IFault'
import UFDatePicker, { UFFormatDate } from '../../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import UFTimePicker, { UFFormatTime } from '../../../../../Common/UseForm/UFTimePicker/UFTimePicker'
import UFCheckBox from '../../../../../Common/UseForm/UFCheckBox/UFCheckBox'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import UFAutocomplete from '../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useTranslation } from 'react-i18next'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Mode } from '../../../../../Common/Types/CommonType'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'

import { useAuth } from '../../../../../../contexts/AuthContext'
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        width: '100%',
        marginTop: '1rem',
    })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

interface FaultFormDetailsProps {
    item: IFault
    control: any
    watch: any
    setValue: any
    disabled: boolean
    mode: Mode
}

const FaultFormDetails = (props: FaultFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, watch, item } = props
    const [expanded, setExpanded] = useState<string | false>('')
    //const [user] = useuserContext()
    const { user } = useAuth()
    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">
                <UFSelect
                    setValue={setValue}
                    name="OrganizationID"
                    control={control}
                    label={t('Organization')}
                    idColName="OrganizationID"
                    nameColName="Name"
                    url={user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()}
                    disabled={user!.isSuperUser ? disabled : true}
                    required={t('Required value')}
                    value={user!.OrganizationID.toString()}
                />
                <Accordion expanded={expanded === 'panel0'} onChange={handleChangeAccordion('panel0')}>
                    <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
                        {t('Fault details:') + ' ' + UFFormatDate(watch('Date')) + ' ' + UFFormatTime(watch('Hour'))}
                    </AccordionSummary>
                    <AccordionDetails>
                        <UFDatePicker
                            control={control}
                            fullWidth={true}
                            name="Date"
                            label={t('Date')}
                            required={t('Required value')}
                            disabled={disabled}
                        />
                        <UFTimePicker
                            control={control}
                            fullWidth={true}
                            name="Hour"
                            label={t('Hour')}
                            required={t('Required value')}
                            disabled={disabled}
                        />
                    </AccordionDetails>
                </Accordion>

                <UFSelect
                    name="FaultReasonID"
                    setValue={setValue}
                    control={control}
                    label={t('FaultReason')}
                    idColName="FaultReasonID"
                    nameColName="Name"
                    url={'organizations/' + user!.OrganizationID + '/faultreasons'}
                    disabled={disabled}
                />

                <UFCheckBox control={control} name="isFullDayFault" label={t('Full Day')} disabled={disabled} />

                <UFTextField
                    control={control}
                    fullWidth={true}
                    name="Note"
                    label={t('Notes')}
                    disabled={disabled}
                    lines={4}
                />

                <UFAutocomplete
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    url={'organizations/' + user!.OrganizationID + '/students'}
                    parentApi={'faults/' + item.FaultID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />
            </DivFlex>
        </div>
    )
}

export default FaultFormDetails
