import { ReactNode, useEffect, useRef, useState } from 'react'
import './_CommonTabHeader.scss'

import { Box, IconButton, SxProps, Theme, useTheme } from '@mui/material'
import TabList from '@mui/lab/TabList'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
interface commonTabHeaderProps {
    children: ReactNode
    handleTabChange: (event: React.SyntheticEvent, newValue: string) => void
    customSX?: SxProps<Theme> | undefined
}

const CommonTabHeader = (props: commonTabHeaderProps) => {
    const { children, handleTabChange, customSX } = props
    const theme = useTheme()
    const childrenCount = React.Children.count(children)
    const [showScrollIcon, setShowScrollIcon] = useState(false)
    const tabListRef = useRef<HTMLDivElement | null>(null)
    const checkScroll = () => {
        if (tabListRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = tabListRef.current
            // Show the scroll icon if the content overflows and user hasn't scrolled to the end
            setShowScrollIcon(scrollWidth > clientWidth && scrollLeft + clientWidth < scrollWidth)
        }
    }
    useEffect(() => {
        const handleResize = () => checkScroll()
        window.addEventListener('resize', handleResize)
        checkScroll() // Run once on mount

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return (
        <>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    position: 'relative',
                }}
                ref={tabListRef}
                onScroll={checkScroll}
            >
                <TabList
                    variant={childrenCount > 4 ? 'scrollable' : 'fullWidth'}
                    onChange={handleTabChange}
                    sx={typeof customSX !== 'undefined' ? customSX : theme.customSx.globalFormTabList}
                    centered={false}
                    textColor="secondary"
                    indicatorColor="secondary"
                    // scrollButtons={false}
                >
                    {showScrollIcon && (
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 0,
                                zIndex: 1,
                                backgroundColor: 'white',
                                boxShadow: 1,
                            }}
                            onClick={() => {
                                // Scroll to the right smoothly
                                if (tabListRef.current) {
                                    tabListRef.current.scrollBy({
                                        left: 200,
                                        behavior: 'smooth',
                                    })
                                }
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    )}
                    {children}
                </TabList>
            </Box>
        </>
    )
}
export default CommonTabHeader
