// import './_Default.scss'
import { ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Container, AppBar, Stack, Dialog, DialogContent } from '@mui/material'
// import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'

import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import FileDownload from '@mui/icons-material/FileDownload'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
// import SearchIcon from '@mui/icons-material/Search'
// import MoreIcon from '@mui/icons-material/MoreVert'
import AttachFile from '@mui/icons-material/AttachFile'
import Edit from '@mui/icons-material/Edit'

import CustomAvatar from '../../components/Common/CustomAvatar/CustomAvatar'
import CommonTransition from '../../components/Common/CommonTransition/CommonTransition'
import { TabContext } from '@mui/lab'
import React from 'react'
import CommonActionsPanel from '../../components/Common/MenuActions/CommonActionsPanel/CommonActionsPanel'

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    // '@media all': {
    //     minHeight: 128,
    // },
}))

// const drawerWidth = 300

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' && prop !== 'openRight' })<{
    open?: boolean
    openRight?: boolean
}>(({ theme, open, openRight }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}))

// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
// }))

interface profileProps {
    item: any
    children: ReactNode
    onCloseNoSave: () => void
    tabValue: string
    methods: any
    onClickEdit: (prmType: string) => void
    nameLabelValue: string
    IDColName: string
    avatarType: string
}

const Profile = (props: profileProps) => {
    const { t } = useTranslation()
    const { children, onCloseNoSave, onClickEdit, tabValue, methods, item, nameLabelValue, IDColName, avatarType } =
        props

    const {
        control,
        setValue,
        formState: { errors },
    } = methods
    // const [closeProfileAlert, setCloseProfileAlert] = useState(false)
    const [reRenderForm, setReRenderForm] = useState(false)

    return (
        <>
            <Dialog open={true} onClose={onCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
                <DialogContent sx={{ position: 'relative', width: '100%', height: '100vh' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static">
                            <StyledToolbar>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="secondary"
                                    aria-label="open drawer"
                                    sx={{ mr: 2 }}
                                    onClick={onCloseNoSave}
                                >
                                    <ChevronLeft />
                                </IconButton>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ flexGrow: 1, alignSelf: 'flex-end', pt: 1 }}
                                ></Typography>

                                {/*<IconButton size="large" aria-label="display more actions" edge="end" color="inherit">
                                    <AttachFile />
                                </IconButton>
                                <IconButton size="large" aria-label="search" color="inherit">
                                    <FileDownload />
                                </IconButton>*/}
                                <CommonActionsPanel menuActionType="form" />
                                <IconButton
                                    size="large"
                                    aria-label="search"
                                    color="secondary"
                                    onClick={() => onClickEdit('namedetails')}
                                >
                                    <Edit />
                                </IconButton>
                            </StyledToolbar>

                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <CustomAvatar
                                    itemType={avatarType}
                                    keyName={IDColName}
                                    item={item}
                                    sizeHeight={100}
                                    sizeWidth={100}
                                    justViewMode={true}
                                />
                            </Stack>
                            {/* terminar avatar */}
                            <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1, alignSelf: 'center' }}>
                                {nameLabelValue}
                            </Typography>
                        </AppBar>
                    </Box>
                    <TabContext value={tabValue}>
                        {React.Children.map(children, child => {
                            if (React.isValidElement(child)) {
                                const propsToPass = { control, setValue, errors }
                                return React.cloneElement(child, propsToPass)
                            }
                            return child
                        })}
                    </TabContext>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default Profile
