import React, { useEffect, useRef, useState } from 'react'
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Autocomplete,
    InputAdornment,
    IconButton,
    Fab,
    Box,
    Container,
    ListItemIcon,
    Checkbox,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomAvatar from '../../CustomAvatar/CustomAvatar'

import { Mode, Operation } from '../../Types/CommonType'
import CancelIcon from '@mui/icons-material/Cancel'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import './_CommonInfiniteScrollPaginatedList.scss'
import AddIcon from '@mui/icons-material/Add'
import CommonForm from '../../CommonForm/CommonForm'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { BULKICON, CHECKALLICON } from '../../../../styles/_icons'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import AppsIcon from '@mui/icons-material/Apps'
import ReorderIcon from '@mui/icons-material/Reorder'
interface CommonInfiniteScrollPaginatedListProps {
    loadMore: () => void
    items: any[]
    hasMore: boolean
    itemType?: string
    CRUDEForm?: React.ComponentType<any>
    CRUDEFormURL?: string
    //setFilter: (value: string) => void;
    filter: string
    hideAddition?: boolean
    handleAdd: (item: any) => void
    handleEdit: (item: any) => void
    handleDelete: (id: string, itemsArray: any[]) => void
    handleError: (error: string) => void
    handleFilterChange: (event: any) => void
    page: number
    variant?: 'default' | 'other'
    componentParams?: any
    hideMultiSelect?: boolean
}

const CommonInfiniteScrollPaginatedList: React.FC<CommonInfiniteScrollPaginatedListProps> = ({
    loadMore,
    items,
    hasMore,
    itemType,
    CRUDEForm,
    CRUDEFormURL,
    filter,
    hideAddition,
    handleAdd,
    handleEdit,
    handleDelete,
    handleError,
    handleFilterChange,
    page,
    variant = 'default',
    componentParams,
    hideMultiSelect,
}) => {
    const { t } = useTranslation()
    const { hasActions } = useCurrentScreen()
    const [selectedID, setSelectedID] = useState<number>(0)
    const observerRef = useRef<HTMLLIElement>(null)
    const [formOpeningMode, setFormOpeningMode] = useState<Mode>(Mode.None)
    const [reRenderForm, setReRenderForm] = useState<boolean>(false)
    const [isGettingData, setIsGettingData] = useState<boolean>(true)
    const [checkedItems, setCheckedItems] = useState<number[]>([])
    const [bulkMode, setBulkMode] = useState<boolean>(false)
    const [viewMode, setViewMode] = useState<boolean>(true)
    const autoCompleteBoxSx =
        componentParams.showToggle || componentParams.showPieChart
            ? {
                  //   width: '100%',
                  //   position: 'fixed',
                  //   top: '120px',
                  //   display: 'flex',
                  //   justifyContent: 'center',
                  //   alignItems: 'center',
                  //   bgcolor: 'background.paper',
                  //   zIndex: 1099,
                  //   padding: '1rem',
                  marginTop: '5px',
                  marginBottom: '1rem',
                  '@media (max-width: 600px)': {
                      top: '111px',
                  },
              }
            : {
                  width: '100%',
                  position: 'fixed',
                  top: '64px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'white',
                  zIndex: 1099,
                  padding: '1rem',
                  marginTop: '64px',
                  marginBottom: '1rem',
                  '@media (max-width: 600px)': {
                      top: '111px',
                  },
              }

    const autocompleteSx = {
        width: '100%',
        height: '50px', // Adjust the height as needed for the entire component
        '& .MuiAutocomplete-inputRoot': {
            height: '100%',
            '& .MuiInputBase-input': {
                height: '100%', // Ensure the input field covers the entire height
                padding: '16px 14px', // Adjust padding as needed
            },
        },
    }

    const sxList =
        componentParams.showToggle || componentParams.showPieChart
            ? {
                  position: 'relative',
                  backgroundColor: 'Transparent',
                  // padding: '20px',
                  width: '100%',
                  top: componentParams.filter ? '0px' : '0px',
              }
            : {
                  position: 'relative',
                  backgroundColor: 'Transparent',
                  // padding: '20px',
                  width: '100%',
                  top: componentParams.filter ? '0px' : '0px',
              }

    //const { setLoading, isLoading } = useLoading();
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        if (entry.isIntersecting && hasMore && !isGettingData) {
            setIsGettingData(true)
            loadMore()
            if (page === 1) {
                setBulkMode(false)
                setCheckedItems([])
            }
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        })

        if (observerRef.current) {
            observer.observe(observerRef.current)
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current)
            }
        }
    }, [hasMore, isGettingData, loadMore])

    useEffect(() => {
        setIsGettingData(false)
    }, [items])

    useEffect(() => {
        setBulkMode(false)
        setCheckedItems([])
        loadMore
    }, [filter])

    const handleOpenFormEdit = (id: string) => {
        setFormOpeningMode(Mode.DeleteEdit)
        setSelectedID(parseInt(id))
    }

    const handleOpenAdd = (event: React.FormEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setFormOpeningMode(Mode.Add)
        //dispatch({ type: 'CreateItem' })
    }

    const handlImageUpdated = (filename: string, id?: number) => {
        console.log('handlImageUpdated', filename, id)
    }

    function handleSave<T>(item: T, afterOp: Operation, error?: string): void {
        const tmp = item as unknown as T

        //setLoading(false)
        if (error) {
            handleError(error)
        } else {
            switch (afterOp) {
                case Operation.Add:
                    setFormOpeningMode(Mode.None)
                    setReRenderForm(!reRenderForm)
                    handleAdd(tmp)

                    break
                case Operation.Edit: {
                    setFormOpeningMode(Mode.None)
                    setReRenderForm(!reRenderForm)
                    handleEdit(tmp)

                    break
                }

                case Operation.Delete: {
                    setFormOpeningMode(Mode.None)
                    const tmpArra = [] as any
                    tmpArra.push(Object.values(tmp as any)[0])
                    handleDelete(Object.keys(tmp as any)[0], tmpArra)

                    break
                }
            }
        }
    }

    const handleNoSave = () => {
        setFormOpeningMode(Mode.None)
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Ctrl (or Cmd) and S keys are pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
                event.preventDefault()
                event.stopPropagation()
                setFormOpeningMode(Mode.Add)
            }
        }

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown)

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleOpenAdd])

    const handleCheckBox = (id: number) => {
        setCheckedItems(
            prevChecked =>
                prevChecked.includes(id)
                    ? prevChecked.filter(item => item !== id) // Uncheck if already selected
                    : [...prevChecked, id] // Add to selected items if not already selected
        )
    }
    const handleSelectAll = () => {
        if (checkedItems.length === items.length) {
            // If all are selected, uncheck all
            setCheckedItems([])
        } else {
            // Otherwise, select all
            const allIndexes = items.map((_, index) => index)
            setCheckedItems(allIndexes)
        }
    }
    const handleToggleBulkMode = () => {
        setBulkMode(!bulkMode)
        if (!bulkMode) {
            setCheckedItems([])
        }
    }
    const handleToggleViewMode = () => {
        setViewMode(!viewMode)
    }

    return (
        <>
            <Container sx={{ marginTop: 0 }}>
                {componentParams.filter && (
                    <Box sx={autoCompleteBoxSx}>
                        <Autocomplete
                            sx={autocompleteSx}
                            freeSolo
                            // className="common-autocomplete"
                            options={items.map(itm => itm['Name'])}
                            inputValue={filter}
                            onInputChange={(event, newInputValue) => {
                                handleFilterChange(event)
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t('Filter')}
                                    onChange={handleFilterChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onMouseUp={() => {
                                                        //setFilter('')
                                                        handleFilterChange(null as any)
                                                    }}
                                                    onMouseDown={e => e.preventDefault()}
                                                >
                                                    {filter === '' ? null : <CancelIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FilterAltIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Box>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
                    <Box display="flex" gap={1}>
                        {!hideMultiSelect && hasActions && (
                            <IconButton color="primary" onClick={handleToggleBulkMode} edge={false}>
                                <CustomSVG strSVG={BULKICON} customColor={bulkMode ? 'primary' : 'secondary'} />
                            </IconButton>
                        )}
                        {!hideMultiSelect && bulkMode && hasActions && (
                            <IconButton color="primary" onClick={handleSelectAll}>
                                <CustomSVG
                                    strSVG={CHECKALLICON}
                                    customColor={checkedItems.length === items.length ? 'primary' : 'secondary'}
                                />
                            </IconButton>
                        )}
                    </Box>
                    {componentParams.showToggle && (
                        <IconButton color="primary" onClick={handleToggleViewMode} edge={false}>
                            {viewMode === true ? <ReorderIcon /> : <AppsIcon />}
                        </IconButton>
                    )}
                </Box>
                <List sx={sxList}>
                    {items.map((item: any, index) => (
                        <ListItem
                            key={index}
                            onClick={
                                bulkMode
                                    ? () => handleCheckBox(index)
                                    : () => handleOpenFormEdit(item[Object.keys(item)[0]])
                            }
                            divider
                            sx={{
                                cursor: 'pointer',
                                userSelect: 'none',
                                mb: '1rem',
                                backgroundColor: bulkMode && checkedItems.includes(index) ? 'primary.light' : 'white',
                            }}
                        >
                            {typeof item.ProfileImg !== 'undefined' && (
                                <ListItemAvatar>
                                    <CustomAvatar
                                        item={item}
                                        keyName={Object.keys(item)[0]}
                                        itemType={
                                            componentParams.avatarType ? componentParams.avatarType : 'images/general'
                                        }
                                        onImageUpdated={handlImageUpdated}
                                        sizeWidth={50}
                                        sizeHeight={50}
                                        justViewMode={true}
                                    />
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                sx={{
                                    wordBreak: 'break-all',
                                    whiteSpace: 'normal',
                                    overflowWrap: 'break-word',
                                    minHeight: 70,
                                }}
                                primary={t(item.primaryText as string)}
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    color: bulkMode && checkedItems.includes(index) ? 'white' : 'primary.main',

                                    fontWeight: 500,
                                }}
                                secondary={item.newSecondaryText}
                                secondaryTypographyProps={{
                                    component: 'p', //check this
                                    variant: 'body2', //check this
                                    fontSize: 15,
                                    color: bulkMode && checkedItems.includes(index) ? 'white' : 'secondary.main',
                                }}
                            />
                            {bulkMode && (
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checkedItems.includes(index)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${index}` }}
                                        sx={
                                            bulkMode && checkedItems.includes(index)
                                                ? {
                                                      color: 'primary.light',
                                                      '&.Mui-checked': {
                                                          color: 'white',
                                                      },
                                                  }
                                                : {}
                                        }
                                    />
                                </ListItemIcon>
                            )}
                        </ListItem>
                    ))}
                    <li ref={observerRef} style={{ height: '10px' }}></li>
                    {(typeof hideAddition === 'undefined' || hideAddition === false) && !bulkMode && (
                        <Fab
                            sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                            }}
                            variant="extended"
                            color="primary"
                            onClick={event => handleOpenAdd!(event)}
                            aria-label={t('Add') + ' ' + 'Ctrl/CMD + A'}
                        >
                            <AddIcon
                                sx={{
                                    position: 'relative',
                                    mr: 1,
                                }}
                            />
                            {t('Add')}
                        </Fab>
                    )}
                </List>
            </Container>
            {formOpeningMode !== Mode.None && CRUDEForm && !bulkMode && (
                <CommonForm
                    mode={formOpeningMode}
                    id={selectedID}
                    onClose={handleSave}
                    onCloseNoSave={handleNoSave}
                    reRender={reRenderForm}
                    Form={CRUDEForm}
                    FormURL={typeof CRUDEFormURL === 'undefined' ? '' : CRUDEFormURL}
                />
            )}

            {page === -1 && items.length === 0 && <>{t('No Values')}</>}
            {page === -666 && items.length === 0 && <>{t('Session Expired')}</>}
        </>
    )
}

CommonInfiniteScrollPaginatedList.defaultProps = {
    componentParams: {
        PageSize: -1,
        showToggle: false,
        filter: null,
        avatarType: 'images/general',
        showPieChart: false,
        pieChartNameAttr: '',
        pieChartValAttr: '',
    },
}

export default CommonInfiniteScrollPaginatedList
