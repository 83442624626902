import { Container, Dialog, DialogContent, Tab, useTheme } from '@mui/material'
import FooterToolBar from '../../../../../../Common/FooterToolBar/FooterToolBar'
import { TabContext, TabList } from '@mui/lab'
import { IStudent } from '../../../IStudent'
import { Mode, Operation } from '../../../../../../Common/Types/CommonType'
import CancelToolBar from '../../../../../../Common/CancelToolBar/CancelToolBar'
import StudentFormDetails from '../Details/StudentFormDetails'
import StudentCard from '../../../Card/StudentCard'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CustomSVG from '../../../../../../Common/CustomSVG/CustomSVG'
import {
    CAREGROUPSICON,
    CARESICON,
    FAULTSICON,
    INFOICON,
    MEALGROUPSICON,
    MEALSICON,
    USERSICON,
} from '../../../../../../../styles/_icons'
import CommonTransition from '../../../../../../Common/CommonTransition/CommonTransition'
import CareFormat from '../../../../../Cares/Care/List/ListItemTextAttributes'
import CareGroupFormat from '../../../../../Cares/CareGroup/List/ListItemTextAttributes'
import FaultFormat from '../../../../../Cares/Fault/List/ListItemTextAttributes'
import MealFormat from '../../../../../Meals/Meal/List/ListItemTextAttributes'
import MealGroupFormat from '../../../../../Meals/MealGroup/List/ListItemTextAttributes'
import UserFormat from '../../../../../Administration/User/List/ListItemTextAttributes'
import CommonInfiniteScrollList from '../../../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import { useAlert } from '../../../../../../../contexts/AlertContext'
import { Methods } from '../../../../../../../Utils/Utils'
import UserForm from '../../../../../Administration/User/Form/Regular/UserForm'
import CareGroupForm from '../../../../../Cares/CareGroup/Form/CareGroupForm'
import MealGroupForm from '../../../../../Meals/MealGroup/Form/MealGroupForm'
import CareForm from '../../../../../Cares/Care/Form/CareForm'
import FaultForm from '../../../../../Cares/Fault/Form/FaultForm'
import MealForm from '../../../../../Meals/Meal/Form/MealForm'
import CommonTabContainer from '../../../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../../../Common/CommonTabHeader/CommonTabHeader'
import Profile from '../../../../../../../layouts/Profile/Profile'

interface StudentFormAddProps {
    item: IStudent
    mode: Mode
    operation: Operation
    handleOnCloseNoSave: () => void
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    editMode: string
    onImageUpdated: (filename: string) => void
    handleEditCard: (prmType: string) => void
}

const StudentFormAdd = (props: StudentFormAddProps) => {
    const { item, mode, operation, handleOnCloseNoSave, editMode, onImageUpdated, onClose, handleEditCard } = props
    const [tabValue, setTabValue] = useState('1')
    const { t } = useTranslation()
    const methods = useForm<IStudent>({ defaultValues: item })
    const { handleSubmit, control, setValue } = methods

    const theme = useTheme()
    const { setAlert } = useAlert()
    const [counters, setCounters] = useState<{ [key: string]: string }>({
        cares: '0',
        meals: '0',
        faults: '0',
        users: '0',
        caregroups: '0',
        mealgroups: '0',
    })
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }
    const handlImageUpdated = (filename: string) => {
        onImageUpdated(filename)
    }

    const handleSwipe = (tab: number) => {
        // Handle swipe gestures and update activeTab accordingly
        // You may use a library like swipe-js-iso or implement your own logic
        setTabValue(tab.toString())
    }

    useEffect(() => {
        const getCountersData = async () => {
            const urls = [
                'students/' + item.StudentID + '/cares?count=1',
                'students/' + item.StudentID + '/meals?count=1',
                'students/' + item.StudentID + '/faults?count=1',
                'students/' + item.StudentID + '/users?count=1',
                'students/' + item.StudentID + '/caregroups?count=1',
                'students/' + item.StudentID + '/mealgroups?count=1',
            ]

            try {
                // Directly call the static method to fetch all data
                const countersData = await Methods.getCountersBulk(urls)

                // Update the state with the fetched data
                setCounters(prevCounters => ({
                    ...prevCounters,
                    ...countersData,
                }))
            } catch (error) {
                setAlert({
                    message: t('Error'),
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            }
        }
        if (mode === Mode.DeleteEdit) {
            getCountersData()
        }
    }, [])

    return (
        <Profile
            item={item}
            onCloseNoSave={handleOnCloseNoSave}
            tabValue={tabValue}
            methods={methods}
            onClickEdit={handleEditCard}
            nameLabelValue={item.Name + ' ' + item.Surname1 + ' ' + item.Surname2}
            IDColName="StudentID"
            avatarType="images/student"
        >
            <CommonTabHeader handleTabChange={handleTabChange} customSX={theme.customSx.globalFormTabListForProfile}>
                <Tab label={t('Details')} value="1" icon={<CustomSVG strSVG={INFOICON} />} />
                {mode === Mode.DeleteEdit && parseInt(counters.cares) > 0 && (
                    <Tab
                        label={t('Cares') + '(' + counters.cares + ')'}
                        value="4"
                        icon={<CustomSVG strSVG={CARESICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('Meals') + '(' + counters.meals + ')'}
                        value="6"
                        icon={<CustomSVG strSVG={MEALSICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.faults) > 0 && (
                    <Tab
                        label={t('Faults') + '(' + counters.faults + ')'}
                        value="5"
                        icon={<CustomSVG strSVG={FAULTSICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.users) > 0 && (
                    <Tab
                        label={t('Contacts') + '(' + counters.users + ')'}
                        value="2"
                        icon={<CustomSVG strSVG={USERSICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.caregroups) > 0 && (
                    <Tab
                        label={t('CareGroups') + '(' + counters.caregroups + ')'}
                        value="3"
                        icon={<CustomSVG strSVG={CAREGROUPSICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.mealgroups) > 0 && (
                    <Tab
                        label={t('Meal Groups') + '(' + counters.mealgroups + ')'}
                        value="7"
                        icon={<CustomSVG strSVG={MEALGROUPSICON} />}
                    />
                )}
            </CommonTabHeader>
            {mode === Mode.Add && (
                <CommonTabContainer displayTab={'1' === tabValue}>
                    <Container>
                        <StudentFormDetails
                            control={control}
                            item={item}
                            setValue={setValue}
                            disabled={operation === Operation.Delete ? true : false}
                            onImageUpdated={handlImageUpdated}
                        />
                    </Container>
                </CommonTabContainer>
            )}

            {editMode === '' && mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'1' === tabValue}>
                    <StudentCard item={item} handleEditCard={handleEditCard} />
                </CommonTabContainer>
            )}
            <CommonTabContainer displayTab={'4' === tabValue}>
                <CommonInfiniteScrollList
                    url={'students/' + item.StudentID + '/cares'}
                    isVisibleNow={tabValue === '4'}
                    listItemTextAttributes={CareFormat}
                    CRUDEForm={CareForm}
                    hideAddition={true}
                    CRUDEFormURL="care"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
            <CommonTabContainer displayTab={'5' === tabValue}>
                <CommonInfiniteScrollList
                    url={'students/' + item.StudentID + '/faults'}
                    isVisibleNow={tabValue === '5'}
                    listItemTextAttributes={FaultFormat}
                    CRUDEForm={FaultForm}
                    hideAddition={true}
                    CRUDEFormURL="fault"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
            <CommonTabContainer displayTab={'6' === tabValue}>
                <CommonInfiniteScrollList
                    url={'students/' + item.StudentID + '/meals'}
                    isVisibleNow={tabValue === '6'}
                    listItemTextAttributes={MealFormat}
                    CRUDEForm={MealForm}
                    hideAddition={true}
                    CRUDEFormURL="meal"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
            <CommonTabContainer displayTab={'2' === tabValue}>
                <CommonInfiniteScrollList
                    url={'students/' + item.StudentID + '/users'}
                    isVisibleNow={tabValue === '2'}
                    listItemTextAttributes={UserFormat}
                    CRUDEForm={UserForm}
                    hideAddition={true}
                    componentParams={{ avatarType: 'images/user' }}
                    CRUDEFormURL="user"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
            <CommonTabContainer displayTab={'3' === tabValue}>
                <CommonInfiniteScrollList
                    url={'students/' + item.StudentID + '/caregroups'}
                    isVisibleNow={tabValue === '3'}
                    listItemTextAttributes={CareGroupFormat}
                    CRUDEForm={CareGroupForm}
                    hideAddition={true}
                    CRUDEFormURL="caregroup"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
            <CommonTabContainer displayTab={'7' === tabValue}>
                <CommonInfiniteScrollList
                    url={'students/' + item.StudentID + '/mealgroups'}
                    isVisibleNow={tabValue === '7'}
                    listItemTextAttributes={MealGroupFormat}
                    CRUDEForm={MealGroupForm}
                    hideAddition={true}
                    CRUDEFormURL="mealgroup"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
        </Profile>
    )
}

export default StudentFormAdd
