import './_SaveCancelToolBar.scss'
import { Mode, Operation } from '../Types/CommonType'
import { ICommon } from '../Types/ICommon'
// import DoneIcon from '@mui/icons-material/Done'
// import CloseIcon from '@mui/icons-material/Close'
import { ArrowBack, Done, Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { useLoading } from '../../../contexts/LoadingContext'
import { useAlert } from '../../../contexts/AlertContext'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../Utils/APIService'
import { useEffect } from 'react'
import CommonActionsPanel from '../MenuActions/CommonActionsPanel/CommonActionsPanel'

type Handler = <T>(item: T, afterOp: Operation, error?: string) => void

interface SaveCancelToolBarProps {
    url: string
    item: ICommon
    idName: string
    title: string
    operation: Operation
    mode: Mode
    onSave: Handler //so we can close the modal
    onCloseNoSave: () => void
    handleSubmit: any
    hideCommonActions?: boolean
}

const SaveCancelToolBar = (props: SaveCancelToolBarProps) => {
    const { onSave, onCloseNoSave, url, operation, handleSubmit, title, idName, item, hideCommonActions } = props
    //const [user] = useuserContext()
    const { user } = useAuth()
    const { t } = useTranslation()
    const { setLoading } = useLoading()
    const { setAlert } = useAlert()

    const handleAdd = async (prmItem: ICommon) => {
        const tmpURL = process.env.REACT_APP_METHODS_API + url

        if (prmItem.OrganizationID === undefined) {
            prmItem.OrganizationID = user!.OrganizationID
        }

        const response = await api.post(tmpURL, JSON.stringify(prmItem))

        if (!response.data) {
            // get error message from body or default to response status
            const error = (response.data && response.data.message) || response.status
            onSave(prmItem, Operation.Add, error)
            setAlert({
                message: error,
                severity: 'error',
                datetime: Date.now().toString(),
            })
            return Promise.reject(error)
        } else {
            setAlert({
                message: t('Record added successfully.'),
                severity: 'success',
                datetime: Date.now().toString(),
            })
            onSave(response.data, Operation.Add)
        }

        setLoading(false)
    }

    const handleEdit = async (prmItem: ICommon) => {
        const tmpURL = process.env.REACT_APP_METHODS_API + url

        if (prmItem.OrganizationID === undefined) {
            prmItem.OrganizationID = user!.OrganizationID
        }

        const response = await api.put(tmpURL, JSON.stringify(prmItem))

        if (!response.data) {
            // get error message from body or default to response status
            const error = (response.data && response.data.message) || response.status
            onSave(prmItem, Operation.Edit, error)
            setAlert({
                message: error,
                severity: 'error',
                datetime: Date.now().toString(),
            })
            return Promise.reject(error)
        } else {
            setAlert({
                message: t('Record updated successfully.'),
                severity: 'success',
                datetime: Date.now().toString(),
            })
            onSave(response.data, Operation.Edit)
        }

        setLoading(false)
    }

    const handleDelete = async (prmItem: ICommon) => {
        // @ts-ignore
        const tmpID = prmItem[idName]
        const tmpURL =
            process.env.REACT_APP_METHODS_API +
            url +
            '/' +
            tmpID +
            '/' +
            prmItem.UserName +
            '/' +
            prmItem.OpUserID +
            '/' +
            user!.OrganizationID

        const response = await api.delete(tmpURL)

        if (!response.data) {
            // get error message from body or default to response status
            const error = (response.data && response.data.message) || response.status
            onSave(prmItem, Operation.Delete, error)
            setAlert({
                message: error,
                severity: 'error',
                datetime: Date.now().toString(),
            })
            return Promise.reject(error)
        } else {
            setAlert({
                message: t('Record deleted successfully.'),
                severity: 'success',
                datetime: Date.now().toString(),
            })
            onSave(response.data, Operation.Delete)
        }
        setLoading(false)
    }

    //const handleSave = (event: React.FormEvent<HTMLButtonElement>) => {
    const handleSave = (data: ICommon) => {
        //event.preventDefault();
        setLoading(true)
        data.UserName = user!.userName
        data.OpUserID = user!.OpUserID
        data.OrganizationID = data.OrganizationID === -1 ? user!.OrganizationID : data.OrganizationID
        switch (operation) {
            case Operation.Add:
                handleAdd(data)
                break
            case Operation.Edit:
                handleEdit(data)
                break
            case Operation.Delete:
                handleDelete(data)

                break
            default:
        }
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Ctrl (or Cmd) and S keys are pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault()
                handleSubmit(handleSave)()
            }
            if (event.key === 'Escape') {
                onCloseNoSave()
            }
        }

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown)

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleSubmit])

    return (
        <>
            <AppBar position="static" color="inherit">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label={t('Close') + ' ' + 'Esc'}
                        onClick={onCloseNoSave}
                        sx={{ mr: 2 }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flex: 1 }}>
                        {t(Operation[operation]) + ' ' + title}
                    </Typography>
                    {!hideCommonActions && (
                        <CommonActionsPanel
                            item={item}
                            formIDColName={idName}
                            formAPI={url}
                            primaryKeyName={idName}
                            onSave={onSave}
                            menuActionType="form"
                        />
                    )}
                    <div>
                        <IconButton
                            size="large"
                            aria-label={t('Save') + ' ' + 'Ctrl/Cmd + S'}
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleSubmit(handleSave)}
                            color="inherit"
                        >
                            <Done />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}
export default SaveCancelToolBar
