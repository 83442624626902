import './_UserCard.scss'
import { IUser } from '../IUser'
import { useTranslation } from 'react-i18next'
import CustomAvatar from '../../../../Common/CustomAvatar/CustomAvatar'
import CardDetails from '../../../../../layouts/CardDetails/CardDetails'
import ContactDetails from './ContactDetails/ContactDetails'
import NameDetails from './NameDetails/NameDetails'
import CredentialDetails from './CredentialDetails/CredentialDetails'
import RoleDetails from './RoleDetails/RoleDetails'
import StudentDetails from './StudentDetails/StudentDetails'

interface ICardProps {
    item: IUser
    handleEditCard: (prmType: string) => void
}

const UserCard = (props: ICardProps) => {
    const { handleEditCard, item } = props
    const { t } = useTranslation()
    return (
        <>
            <CardDetails cardLabel="Contact Details" cardType="contactdetails" handleEditCard={handleEditCard}>
                <ContactDetails item={item} />
            </CardDetails>

            <CardDetails
                cardLabel="Credential Details Information"
                cardType="credentialdetails"
                handleEditCard={handleEditCard}
            >
                <CredentialDetails item={item} />
            </CardDetails>

            <CardDetails cardLabel="Role Details Information" cardType="roledetails" handleEditCard={handleEditCard}>
                <RoleDetails item={item} />
            </CardDetails>

            <CardDetails
                cardLabel="Student Details Information"
                cardType="studentdetails"
                handleEditCard={handleEditCard}
            >
                <StudentDetails item={item} />
            </CardDetails>
        </>
    )
}

export default UserCard
