import './_MealForm.scss'
import { IMeal } from '../IMeal'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tab from '@mui/material/Tab'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import MealFormDetails from './Details/MealFormDetails'
import { useForm } from 'react-hook-form'
import React from 'react'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import MealFormat from '../List/ListItemTextAttributes'
import { useAuth } from '../../../../../contexts/AuthContext'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import FormLayout from '../../../../../layouts/FormLayout/FormLayout'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import CommonTabContainer from '../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../Common/CommonTabHeader/CommonTabHeader'
import { Container } from '@mui/material'

const MealForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const methods = useForm<IMeal>({ defaultValues: item })
    const { control, setValue, watch } = methods
    const [tabValue, setTabValue] = useState('1')
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0' })

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const hanleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }

    const urls = ['']
    return (
        <FormLayout
            countersURLs={urls}
            operation={operation}
            mode={mode}
            item={item}
            onClose={onClose}
            onCloseNoSave={onCloseNoSave}
            onCountersLoaded={hanleCountersLoaded}
            formLabelName="Meal"
            formIDColName="MealID"
            formNameColName="Name"
            formAPI="meals"
            methods={methods}
            tabValue={tabValue}
        >
            <CommonTabHeader handleTabChange={handleTabChange}>
                <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
                {mode === Mode.DeleteEdit && parseInt(counters.students) > 0 && (
                    <Tab label={t('Students') + '(' + counters.students + ')'} value="2" icon={<AccountBoxIcon />} />
                )}
            </CommonTabHeader>

            <CommonTabContainer displayTab={'1' === tabValue}>
                <Container>
                    <MealFormDetails
                        mode={mode}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        item={item}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            </CommonTabContainer>
            <CommonTabContainer displayTab={'2' === tabValue}>
                <CommonInfiniteScrollList
                    url={'organizations/' + user!.OrganizationID + '/meals'}
                    isVisibleNow={tabValue === '2'}
                    listItemTextAttributes={MealFormat}
                    CRUDEForm={MealForm}
                    hideAddition={true}
                    CRUDEFormURL="meal"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
        </FormLayout>
    )
}

export default MealForm
