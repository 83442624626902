import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    DialogTitle,
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    CircularProgress,
} from '@mui/material'
import './_CommonActionsDialog.scss'
import { TransitionProps } from '@mui/material/transitions'
import React, { Suspense, lazy, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSVG from '../../CustomSVG/CustomSVG'
import CommonAlert from '../../CommonAlert/CommonAlert'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../../../contexts/AuthContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IAction } from '../IAction'
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface CommonActionsDialogProps {
    open: boolean
    handleClose: () => void
    menuActions: IAction[]
    componentMap: any
    showMenuActionForm?: boolean
    onProceed: (data: any) => void
}

interface CustomDialogProps {
    componentPath?: string // The path to the component
    onProceed?: (data: any) => void // Callback function for when the action is completed
}
const CommonActionsDialog = (props: CommonActionsDialogProps) => {
    const { open, handleClose, menuActions, componentMap, showMenuActionForm, onProceed } = props
    const [DynamicComponent, setDynamicComponent] = useState<React.ComponentType<any> | null>(null)
    const [selectedComponentName, setSelectedComponentName] = useState<string>('')
    const [selectedbulkDropDownURL, setSelectedbulkDropDownURL] = useState<string>('')
    const [selectedComponentRequiresConfirmation, setSelectedComponentRequiresConfirmation] = useState<boolean>(false)
    const [selectedComponentKeepModalOpened, setSelectedComponentKeepModalOpened] = useState<boolean>(false)
    const [selectedComponentRequiresListRecordsSelected, setSelectedComponentRequiresListRecordsSelected] =
        useState<boolean>(false)

    const [selectedComponentShortCode, setSelectedComponentShortCode] = useState<string>('')
    const { t } = useTranslation()
    const methods = useForm({})
    const { control, setValue, watch } = methods
    const { user } = useAuth()
    const handleProceed = (prm: any) => {
        const tmpSendToServer = {
            actionObject: prm,
            requiresConfirmation: selectedComponentRequiresConfirmation,
            requiresListRecordsSelected: selectedComponentRequiresListRecordsSelected,
            type: selectedComponentShortCode,
        }
        onProceed(tmpSendToServer)
    }

    const handleComponenttoRender = async (index: number) => {
        console.log('handleComponenttoRender')

        const tmp = menuActions[index].componentToRenderPath
        setSelectedComponentName(menuActions[index].label)
        let tmpBulkDropdownURL = menuActions[index].bulkDropDownURL + ''
        tmpBulkDropdownURL = tmpBulkDropdownURL.replace('{idd}', user!.OrganizationID + '')
        setSelectedbulkDropDownURL(tmpBulkDropdownURL)
        setSelectedComponentRequiresConfirmation(menuActions[index].requiresActionConfirmation || false)
        setSelectedComponentRequiresListRecordsSelected(menuActions[index].requiresBulkRecordsSelected || false)
        setSelectedComponentShortCode(menuActions[index].shortCode)
        setSelectedComponentKeepModalOpened(menuActions[index].keepActionsDialogOpened || true)

        if (tmp) {
            const tmpDynamicComponent = componentMap[index] as React.ComponentType<any>
            if (tmpDynamicComponent) {
                setDynamicComponent(tmpDynamicComponent)
            }
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    position: 'fixed',
                    bottom: 0,
                    m: 0,
                    width: '100%',
                    height: '50%',
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    padding: '16px',
                },
            }}
        >
            <IconButton
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    zIndex: 1,
                }}
            >
                <ArrowBackIcon />
            </IconButton>
            {/*<DialogTitle>
                {!showMenuActionForm && (
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="secondary"
                                aria-label="menu"
                                onClick={handleClose}
                            >
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color="secondary">
                                Actions
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )}
                </DialogTitle>*/}
            <CommonAlert />
            <DialogContent sx={{ paddingTop: '24px' }}>
                {!showMenuActionForm &&
                    menuActions.map((action, index) => (
                        <Box key={index} sx={{ width: '100%', px: 2 }}>
                            <Button
                                key={index}
                                onClick={() => {
                                    action.onClick()

                                    handleComponenttoRender(index)

                                    if (!action.keepActionsDialogOpened) {
                                        handleClose()
                                    }
                                }}
                                fullWidth
                                style={{ margin: '10px 0' }}
                                color="secondary"
                            >
                                <CustomSVG strSVG={action.icon} customSX={{ mr: 1 }} customColor="secondary" />
                                {t(action.label)}
                            </Button>
                        </Box>
                    ))}

                {selectedComponentKeepModalOpened && showMenuActionForm && DynamicComponent ? (
                    <Suspense fallback={<>Loading...</>}>
                        {DynamicComponent ? (
                            <DynamicComponent
                                onProceed={(prm: any) => handleProceed(prm)}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                title={selectedComponentName}
                                //dropDownURL={'organizations/' + user!.OrganizationID + '/menuactions?ref=1'}
                                dropDownURL={selectedbulkDropDownURL}
                                dropDownLabel={null}
                            />
                        ) : null}
                    </Suspense>
                ) : null}
            </DialogContent>
            {/*{!showMenuActionForm && (
                <DialogActions>
                    <Box sx={{ width: '100%', px: 1 }}>
                        <Button fullWidth onClick={handleClose} color="secondary">
                            {t('Cancel')}
                        </Button>
                    </Box>
                </DialogActions>
            )}*/}
        </Dialog>
    )
}

export default CommonActionsDialog
