// SocketContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'

interface SocketContextType {
    socket: Socket | null
    isSocketReady: boolean
}

const SocketContext = createContext<SocketContextType | undefined>(undefined)

interface SocketProviderProps {
    children: ReactNode
}

export const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext)
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null)
    const [isSocketReady, setIsSocketReady] = useState(false)

    useEffect(() => {
        console.log('Connecting socket')
        const newSocket = io(process.env.REACT_APP_API_URL + '') // Replace with your server URL
        newSocket.on('connect', () => {
            setSocket(newSocket)
            console.log('Socket connected:', newSocket.id)
            setIsSocketReady(true)
        })

        newSocket.on('disconnect', () => {
            setIsSocketReady(false)
            reconnect(newSocket)
        })

        // Cleanup on unmount
        return () => {
            console.log('Disconnecting socket:', newSocket.id)
            if (newSocket) {
                newSocket.close()
            }
        }
    }, [])

    const reconnect = (newSocket: Socket) => {
        // Attempt to reconnect with exponential backoff
        const reconnectInterval = setInterval(() => {
            if (!newSocket.connected) {
                console.log('Attempting to reconnect...')
                newSocket.connect()
            } else {
                clearInterval(reconnectInterval)
            }
        }, 1000) // Adjust this interval as needed
    }

    return <SocketContext.Provider value={{ socket, isSocketReady }}>{children}</SocketContext.Provider>
}
