import './_UserFormAdd.scss'
import { IUser } from '../../../IUser'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import { Mode, Operation } from '../../../../../../Common/Types/CommonType'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FooterToolBar from '../../../../../../Common/FooterToolBar/FooterToolBar'
import UserFormDetails from '../Details/UserFormDetails'
import { useForm } from 'react-hook-form'
import React from 'react'
import CustomSVG from '../../../../../../Common/CustomSVG/CustomSVG'
import { CARESICON, MEALSICON, STUDENTSICON, INFOICON, USERGROUPSICON } from '../../../../../../../styles/_icons'
import CommonTransition from '../../../../../../Common/CommonTransition/CommonTransition'
import CancelToolBar from '../../../../../../Common/CancelToolBar/CancelToolBar'
import UserCard from '../../../Card/UserCard'
import CareFormat from '../../../../../Cares/Care/List/ListItemTextAttributes'
import MealFormat from '../../../../../Meals/Meal/List/ListItemTextAttributes'
import StudentFormat from '../../../../../Students/Student/List/ListItemTextAttributes'
import CommonInfiniteScrollList from '../../../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import UserGroupFormat from '../../../../UserGroup/List/ListItemTextAttributes'
import CareForm from '../../../../../Cares/Care/Form/CareForm'
import MealForm from '../../../../../Meals/Meal/Form/MealForm'
import StudentForm from '../../../../../Students/Student/Form/Regular/StudentForm'
import UserGroupForm from '../../../../UserGroup/Form/UserGroupForm'
import CommonTabContainer from '../../../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../../../Common/CommonTabHeader/CommonTabHeader'
import { Container, useTheme } from '@mui/material'
import Profile from '../../../../../../../layouts/Profile/Profile'

interface UserFormAddProps {
    item: IUser
    mode: Mode
    operation: Operation
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    onImageUpdated: (filename: string) => void
    counters: { [key: string]: string }
    handleOnCloseNoSave: () => void
    editMode: string
    handleEditCard: (prmType: string) => void
}

const UserFormAdd = (props: UserFormAddProps) => {
    const { t } = useTranslation()
    const methods = useForm<IUser>({ defaultValues: props.item })
    const { control, setValue } = methods
    const { item, mode, operation, handleOnCloseNoSave, editMode, onImageUpdated, counters, onClose, handleEditCard } =
        props
    const [tabValue, setTabValue] = useState('1')
    const [studentsCountValue, setStudentsCountValue] = useState<string>('')
    const [tasklinksCountValue, setTaskLinksCountValue] = useState<string>('')
    const theme = useTheme()
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }
    const handleListLoaded = (name: string, count: number) => {
        console.log('handleListLoaded', name, count)
    }
    const handlImageUpdated = (filename: string) => {
        props.onImageUpdated(filename)
    }
    return (
        <Profile
            item={item}
            onCloseNoSave={handleOnCloseNoSave}
            tabValue={tabValue}
            methods={methods}
            onClickEdit={handleEditCard}
            nameLabelValue={item.Name + ' ' + item.Surname1 + ' ' + item.Surname2}
            IDColName="UserID"
            avatarType="images/user"
        >
            <CommonTabHeader handleTabChange={handleTabChange} customSX={theme.customSx.globalFormTabListForProfile}>
                <Tab label={t('Details')} value="1" icon={<CustomSVG strSVG={INFOICON} />} />
                {mode === Mode.DeleteEdit && parseInt(counters.students) > 0 && (
                    <Tab
                        label={t('My Students') + '(' + counters.students + ')'}
                        value="2"
                        icon={<CustomSVG strSVG={STUDENTSICON} />}
                    />
                )}

                {mode === Mode.DeleteEdit && parseInt(counters.cares) > 0 && (
                    <Tab
                        label={t('Cares') + '(' + counters.cares + ')'}
                        value="4"
                        icon={<CustomSVG strSVG={CARESICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('Meals') + '(' + counters.meals + ')'}
                        value="5"
                        icon={<CustomSVG strSVG={MEALSICON} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('User Groups') + '(' + counters.usergroups + ')'}
                        value="6"
                        icon={<CustomSVG strSVG={USERGROUPSICON} />}
                    />
                )}
            </CommonTabHeader>

            {mode === Mode.Add && (
                <CommonTabContainer displayTab={'1' === tabValue}>
                    <Container>
                        <UserFormDetails
                            control={control}
                            setValue={setValue}
                            item={item}
                            disabled={operation === Operation.Delete ? true : false}
                            onImageUpdated={handlImageUpdated}
                        />
                    </Container>
                </CommonTabContainer>
            )}
            {editMode === '' && mode === Mode.DeleteEdit && <UserCard item={item} handleEditCard={handleEditCard} />}

            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'2' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/students'}
                        isVisibleNow={tabValue === '2'}
                        listItemTextAttributes={StudentFormat}
                        CRUDEForm={StudentForm}
                        hideAddition={true}
                        componentParams={{ avatarType: 'images/student' }}
                        CRUDEFormURL="student"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}

            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'4' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/cares'}
                        isVisibleNow={tabValue === '4'}
                        listItemTextAttributes={CareFormat}
                        CRUDEForm={CareForm}
                        hideAddition={true}
                        CRUDEFormURL="care"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}

            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'5' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/meals'}
                        isVisibleNow={tabValue === '5'}
                        listItemTextAttributes={MealFormat}
                        CRUDEForm={MealForm}
                        hideAddition={true}
                        CRUDEFormURL="meal"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'6' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/usergroups'}
                        isVisibleNow={tabValue === '6'}
                        listItemTextAttributes={UserGroupFormat}
                        CRUDEForm={UserGroupForm}
                        hideAddition={true}
                        CRUDEFormURL="usergroup"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
        </Profile>
    )
}

export default UserFormAdd
