// ActionsPanel.tsx
import React, { lazy, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

import { useTranslation } from 'react-i18next'
import { Methods } from '../../../../Utils/Utils'
import { useAlert } from '../../../../contexts/AlertContext'
import { useLocation } from 'react-router-dom'
import CommonUpload from '../CommonUpload/CommonUpload'
import api from '../../../../Utils/APIService'
import CommonDialog from '../../CommonDialog/CommonDialog'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { TOOLICON } from '../../../../styles/_icons'
import { ICommon } from '../../Types/ICommon'
import CommonInfiniteScrollList from '../../CommonInfiniteScrollList/CommonInfiniteScrollList'
import AuditFormat from '../Audit/List/ListItemTextAttributes'
import CommonConfirmation from '../../CommonConfirmation/CommonConfirmation'
import { useAuth } from '../../../../contexts/AuthContext'
import { Operation } from '../../Types/CommonType'
import CommonActionsDialog from '../CommonActionsDialog/CommonActionsDialog'
import { IAction } from '../IAction'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'

interface ActionsPanelProps {
    formIDColName?: string
    formAPI?: string
    primaryKeyName?: string
    item?: ICommon
    onSave?: <T>(item: T, afterOp: Operation, error?: string) => void
    menuActionType: 'form' | 'list'
    customColor?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'action'
        | 'disabled'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const CommonActionsPanel: React.FC<ActionsPanelProps> = (props: ActionsPanelProps) => {
    const { formIDColName, formAPI, primaryKeyName, item, onSave, menuActionType, customColor } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const { setHasActions } = useCurrentScreen()
    const { setAlert } = useAlert()
    const location = useLocation()
    const { user } = useAuth()
    const [menuActions, setMenuActions] = useState<IAction[]>([])
    const [componentMap, setComponentMap] = useState<any>({})
    const [dialogComponent, setDialogComponent] = useState<React.ReactElement<any, any> | null>(null)
    const [dialogExecuteDelete, setDialogExecuteDelete] = useState(false)
    const [dialogTitle, setDialogTitle] = useState<string>('Dialog')
    const [openDialog, setOpenDialog] = useState(false)
    const lastSection = location.pathname.split('/').filter(Boolean).pop()
    const [openConfirmation, setopenConfirmation] = useState(false)
    const [openMenuActionForm, setOpenMenuActionForm] = useState(false)
    const handleClickOpen = () => {
        setOpenMenuActionForm(false)
        setOpen(true)
    }

    const handleClose = () => {
        if (openMenuActionForm) {
            setOpenMenuActionForm(false)
        } else {
            setOpen(false)
        }

        //setOpenMenuActionForm(false)
    }

    /*const handleCloseDialog = () => {
        setOpenDialog(false)
        setOpenMenuActionForm(false)
    }*/
    const handleOpenDialog = (prm: string) => {
        setDialogTitle(Methods.capitalizeFirstLetters(t(prm)))
        let tmpformIDColName = ''
        let tmpformIDColValue = ''
        if (typeof formIDColName !== 'undefined') {
            tmpformIDColName = formIDColName ? formIDColName : 'ID'
            //@ts-ignore
            tmpformIDColValue = item![tmpformIDColName]
        }

        switch (prm) {
            case 'audit':
                if (typeof formIDColName !== 'undefined') {
                    setDialogComponent(
                        <CommonInfiniteScrollList
                            url={'audits/' + formIDColName + '/' + tmpformIDColValue}
                            isVisibleNow={true}
                            listItemTextAttributes={AuditFormat}
                            hideAddition={true}
                        />
                    )
                }
                setOpenDialog(true)
                break
            case 'delete':
                setDialogComponent(null)
                setDialogExecuteDelete(true)
                setOpenDialog(true)
                break

            case 'upload':
                //setDialogComponent(<CommonUpload />)
                //setOpenDialog(true)
                setOpenMenuActionForm(true)
                break
            case 'downloads':
                setOpenMenuActionForm(true)
                break
            default:
                setOpenMenuActionForm(true)
                break
        }
    }

    useEffect(() => {
        if (dialogExecuteDelete) {
            setopenConfirmation(dialogExecuteDelete)
        }
    }, [dialogExecuteDelete])

    /*const handleDisplayConfirm = () => {
        setopenConfirmation(true)
    }*/
    const handleConfirm = () => {
        handleDelete()
        setopenConfirmation(false)
    }

    const handleCancel = () => {
        setopenConfirmation(false)
    }

    const handleProceed = (prm: any) => {
        console.log(prm.actionObject)

        alert(prm.actionObject)
        alert(prm.type)
        if (prm.requiresConfirmation) {
            setopenConfirmation(prm.requiresConfirmation)
        } else {
            //directly apply the action
        }
    }

    useEffect(() => {
        const getFormActions = async () => {
            const url = 'menus/' + lastSection + '/menuactionsbuttons?ref=1&menuActionType=' + menuActionType

            try {
                const response = await api.get(url)

                if (!response.data) {
                    setAlert({
                        message: response.status + ':' + response.statusText,
                        severity: 'error',
                        datetime: Date.now().toString(),
                    })
                } else {
                    type JsonObject = {
                        MenuActionID: number
                        Name: string
                        Icon: string
                        KeepActionsDialogOpened: boolean
                        ComponentToRenderPath: string
                        RequiresBulkRecordsSelected: boolean
                        RequiresActionConfirmation: boolean
                        BulkDropDownURL: string
                        ShortCode: string
                    }

                    response.data = Methods.JSONUnminify(response.data)

                    setHasActions(response.data.length > 0)

                    const transformedArray = response.data.map(
                        ({
                            Name,
                            Icon,
                            KeepActionsDialogOpened,
                            ComponentToRenderPath,
                            RequiresBulkRecordsSelected,
                            RequiresActionConfirmation,
                            BulkDropDownURL,
                            ShortCode,
                        }: JsonObject) => ({
                            label: Name,
                            icon: Icon,
                            onClick: () => handleOpenDialog(Name.toLowerCase()),
                            keepActionsDialogOpened: KeepActionsDialogOpened,
                            componentToRenderPath: ComponentToRenderPath,
                            requiresBulkRecordsSelected: RequiresBulkRecordsSelected,
                            requiresActionConfirmation: RequiresActionConfirmation,
                            bulkDropDownURL: BulkDropDownURL,
                            shortCode: ShortCode,
                        })
                    )

                    //We have to generate the components this way due (created and passing first the componentMap) to  string interpolation and module imports
                    const importedFormComponents: React.ComponentType<any>[] = []

                    for (const item of transformedArray as IAction[]) {
                        const formComponentModule = lazy(() => import(`../${item.componentToRenderPath}`))
                        importedFormComponents.push(formComponentModule)
                    }

                    setMenuActions(transformedArray)
                    setComponentMap(importedFormComponents)
                }
            } catch (error) {
                setAlert({
                    message: t('Error'),
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            }
        }

        getFormActions()
    }, [lastSection])

    const handleDelete = async () => {
        if (!formAPI || !item || !primaryKeyName) return

        // @ts-ignore
        const tmpID = item[primaryKeyName]

        const tmpURL =
            process.env.REACT_APP_METHODS_API +
            formAPI +
            '/' +
            tmpID +
            '/' +
            user!.userName +
            '/' +
            user!.OpUserID +
            '/' +
            user!.OrganizationID

        const response = await api.delete(tmpURL)

        if (response.data) {
            onSave!(response.data, Operation.Delete)
        } else {
            const error = (response.data && response.data.message) || response.status
            onSave!(item, Operation.Delete, error)
            return Promise.reject(error)
        }
    }

    return (
        <div>
            {menuActions.length > 0 && (
                <IconButton color="primary" onClick={handleClickOpen} edge={false}>
                    <CustomSVG
                        strSVG={TOOLICON}
                        customColor={typeof customColor !== 'undefined' ? customColor : 'secondary'}
                    />
                </IconButton>
            )}

            {openConfirmation && (
                <CommonConfirmation
                    openConfirmation={openConfirmation}
                    message={t('Are you sure you want to proceed?')}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}

            <CommonActionsDialog
                open={open}
                handleClose={handleClose}
                onProceed={handleProceed}
                menuActions={menuActions}
                componentMap={componentMap}
                showMenuActionForm={openMenuActionForm}
            />

            {/*{dialogComponent != null && handleCloseDialog && (
                <CommonDialog open={openDialog!} onClose={handleCloseDialog} title={dialogTitle}>
                    {dialogComponent}
                </CommonDialog>
            )}*/}
        </div>
    )
}

export default CommonActionsPanel
