import {
    Box,
    Collapse,
    Divider,
    Drawer,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    // ListSubheader,
    TextField,
    Tooltip,
    Typography,
    styled,
    useTheme,
    IconButton,
} from '@mui/material'
import { ChevronLeft, ChevronRight, Cancel, Search } from '@mui/icons-material'
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
// import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
// import IconButton from '@mui/material/IconButton'
import { useCallback, useState } from 'react'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Methods } from '../../../../Utils/Utils'
import CustomDrawerSingleLevel from '../CustomDrawerSingleLevel/CustomDrawerSingleLevel'
// import CancelIcon from '@mui/icons-material/Cancel'
// import SearchIcon from '@mui/icons-material/Search'
import { useAuth } from '../../../../contexts/AuthContext'
import DashboardIcon from '@mui/icons-material/Dashboard'

interface CustomDrawerLeftProps {
    openLeft: boolean
    handleOpenLeft(prm: boolean): void
    handleSetPageName(prm: any): void
    handleSetPageIcon(prm: any): void
}

const CustomDrawerLeft = (props: CustomDrawerLeftProps) => {
    const { t, i18n } = useTranslation()
    const { openLeft, handleOpenLeft, handleSetPageName, handleSetPageIcon } = props

    const theme = useTheme()
    //const [session, setSession] = useSessionContext()
    const { user } = useAuth()
    //const session = useRef(JSON.parse(sessionStorage.getItem('session') || '{}'))

    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState('')
    /*const [menuOpenState, setMenuOpenState] = useState<boolean[]>([]);

    useEffect(() => {
        // Simulate fetching data from a database

        setMenuOpenState(new Array(user!.menus.length).fill(false));
    }, []);*/

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
    }

    const filteredItems = user!.menus
        .map((item: any) => ({
            ...item,
            children: item.children?.filter((child: any) => t(child.Name).toLowerCase().includes(filter.toLowerCase())),
        }))
        .filter(
            item =>
                t(item.Name).toLowerCase().includes(filter.toLowerCase()) || (item.children && item.children.length > 0)
        )

    const drawerWidth = 300
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }))
    const handleDrawerClose = () => {
        handleOpenLeft(false)
    }

    const handleListItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()

        const result = Methods.findInJSON(user!.menus, e.currentTarget.id, 'MenuID', 'children')
        switch (e.currentTarget.id) {
            case 'myDashboard':
                handleOpenLeft(false)
                setOpen(false)
                navigate(user!.redirectPath)
                handleSetPageName(t('Eskola'))
                break

            default:
                setOpen(false)
                handleOpenLeft(false)
                handleSetPageName(result.Name)
                handleSetPageIcon(result.Icon)
                navigate(result.Route)

                break
        }
        e.stopPropagation()
    }

    const MultiLevel = ({ item }: any) => {
        const { children } = item
        const [openMulti, setOpenMulti] = useState(false)

        const handleClick = (index: number) => {
            setOpenMulti(prev => !prev)

            /*setMenuOpenState((prev) => {
                const state = [...prev]
                state[index] = !state[index]
                return state
            })*/
        }

        return (
            <>
                <ListItem
                    key={item.MenuID}
                    disablePadding
                    sx={{
                        display: 'block',
                        zIndex: theme => theme.zIndex.modal + 1,
                    }}
                    onClick={() => handleClick(item.MenuID)}
                    id={item.MenuID}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                //mr: openMulti ? 3 : 'auto',
                                ml: item.ParentMenuID != null ? 2 * item.MenuLevel + 'rem' : '3',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={t(item.Name)}>
                                <Box
                                    sx={{
                                        mr: '.5rem',
                                    }}
                                >
                                    <CustomSVG strSVG={item.Icon} />
                                </Box>
                            </Tooltip>
                        </ListItemIcon>

                        <ListItemText primary={t(item.Name)} />
                        {openMulti || filter !== '' ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMulti || filter !== ''} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((child: any, key: any) => (
                            <MenuItemCustom key={key} item={child} />
                        ))}
                    </List>
                </Collapse>
            </>
        )
    }

    const MenuItemCustom = useCallback(
        ({ item }: any) => {
            if (typeof item !== 'undefined') {
                const Component = Methods.hasChildren(item) ? MultiLevel : CustomDrawerSingleLevel
                if (Methods.hasChildren(item)) {
                    return <Component item={item} />
                } else {
                    return <Component item={item} open={open} handleListItemClick={handleListItemClick} />
                }
            } else {
                return <>aaa</>
            }
        },
        [user!.menus, filter]
    )

    return (
        <Drawer
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            anchor="left"
            open={openLeft}
            onClose={handleDrawerClose}
        >
            <DrawerHeader>
                <Typography variant="h6" noWrap>
                    {'Eskola'}
                </Typography>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <TextField
                label={t('Menu Search')}
                variant="outlined"
                value={filter}
                onChange={handleFilterChange}
                sx={{ m: 2, pb: 0 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onMouseUp={() => setFilter('')} onMouseDown={e => e.preventDefault()}>
                                {filter === '' ? null : <Cancel />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
            <List>
                <ListItem
                    key={'myDashboard'}
                    sx={{ display: 'block', paddingLeft: 0, paddingRight: 0 }}
                    onClick={handleListItemClick}
                    id={'myDashboard'}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        aria-label={t('My Dashboard') + ' ' + 'Ctrl/Cmd + M'}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={t('My Dashboard')}>
                                <DashboardIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={t('My Dashboard')} sx={{ opacity: openLeft ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                {filteredItems.map(
                    (item, key) =>
                        Boolean(item.IsHiddenInNavBar) !== true &&
                        (Boolean(item.IsConfigMenu) !== true || typeof item.IsConfigMenu === 'undefined') && (
                            <MenuItemCustom key={key} item={item} />
                        )
                )}
            </List>
        </Drawer>
    )
}
export default CustomDrawerLeft
