import './_CommonConfirmation.scss'
import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface CommonConfirmationProps {
    openConfirmation: boolean
    message: string
    onConfirm: () => void
    onCancel: () => void
}

const CommonConfirmation: React.FC<CommonConfirmationProps> = ({ openConfirmation, message, onConfirm, onCancel }) => {
    const theme = useTheme()
    const handleClose = () => {
        onCancel()
    }

    const handleConfirm = () => {
        onConfirm()
    }

    const { t } = useTranslation()

    return (
        <Dialog
            id="CommonConfirmation"
            open={openConfirmation}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
            PaperProps={{
                sx: {
                    padding: theme.spacing(2), // Add padding around the dialog box
                    borderRadius: 2, // Slightly round the corners for a modern look
                },
            }}
        >
            <DialogTitle>{t('Confirmation')}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    {t('Cancel')}
                </Button>
                <Button onClick={handleConfirm} color="secondary">
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CommonConfirmation
