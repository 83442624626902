import './_MealGroupFormDetails.scss'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFAutocomplete from '../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useTranslation } from 'react-i18next'
import { IMealGroup } from '../../IMealGroup'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useAuth } from '../../../../../../contexts/AuthContext'
interface MealGroupFormDetailsProps {
    item: IMealGroup
    control: any
    disabled: boolean
    setValue: any
}

const MealGroupFormDetails = (props: MealGroupFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, item, setValue } = props
    //const [session] = useSessionContext()
    const { user } = useAuth()
    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Name"
                    label={t('Name')}
                    required="Empty field!"
                    disabled={disabled}
                />
                <UFSelect
                    setValue={setValue}
                    name="OrganizationID"
                    control={control}
                    label={t('Organization')}
                    idColName="OrganizationID"
                    nameColName="Name"
                    url={user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()}
                    disabled={user!.isSuperUser ? disabled : true}
                    required={t('Required value')}
                    value={user!.OrganizationID.toString()}
                />
                <UFSelect
                    name="MealUserID"
                    setValue={setValue}
                    control={control}
                    label={t('MealUserName')}
                    idColName="UserID"
                    nameColName="Name"
                    url={'organizations/' + user!.OrganizationID + '/users'}
                    disabled={disabled}
                    required={t('Required value')}
                />
                <UFAutocomplete
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    url={'organizations/' + user!.OrganizationID + '/students'}
                    parentApi={'mealgroups/' + item.MealGroupID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />
                {/*<UFMultiSelect
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    api={'organizations/' + session.OrganizationID + '/students'}
                    parentApi={'mealgroups/' + item.MealGroupID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
    />*/}
            </DivFlex>
        </div>
    )
}

export default MealGroupFormDetails
