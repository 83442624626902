import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the type for the context value
interface CurrentScreenContextType {
    hasActions: boolean
    setHasActions: (value: boolean) => void
}

// Create the context with default values
const CurrentScreenContext = createContext<CurrentScreenContextType | undefined>(undefined)

// Create a provider component
export const CurrentScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [hasActions, setHasActions] = useState<boolean>(false)

    return (
        <CurrentScreenContext.Provider value={{ hasActions, setHasActions }}>{children}</CurrentScreenContext.Provider>
    )
}

// Custom hook to use the context
export const useCurrentScreen = () => {
    const context = useContext(CurrentScreenContext)
    if (!context) {
        throw new Error('useCurrentScreen must be used within a CurrentScreenProvider')
    }
    return context
}
