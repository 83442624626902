import { createTheme, styled } from '@mui/material/'
// import { blue } from '@mui/material/colors'
import type {} from '@mui/x-data-grid/themeAugmentation' //for the datagrid

/*declare module '@mui/material/styles' {
    interface div {
        variants?: any
    }
}*/

declare module '@mui/material/styles' {
    interface Theme {
        customSx: {
            [key: string]: any
            globalFormTab: (isVisible: boolean) => any
        }
    }

    interface ThemeOptions {
        customSx?: {
            [key: string]: any
            globalFormTab: (isVisible: boolean) => any
        }
    }
}

const eskolaTheme = createTheme({
    // Customize the components
    components: {
        //Reset browser styles

        // Generic CSS tokens
        MuiCssBaseline: {
            styleOverrides: {
                ':root': {
                    '--black': '#000',
                    '--white': '#fff',
                    '--primary-main': '#1D535C',
                    '--primary-light': '#4A757D',
                    '--primary-dark': '#17424A',
                    '--primary-contrast-text': '#fff',
                    '--secondary-main': '#4ECDC4',
                    '--secondary-light': '#71d7d0',
                    '--secondary-dark': '#32b1a8',
                    '--secondary-contrast-text': '#fff',
                    '--error-main': '#d32f2f',
                    '--error-light': '#ef5350',
                    '--error-dark': '#c62828',
                    '--error-contrast-text': '#fff',
                    '--warning-main': '#ed6c02',
                    '--warning-light': '#ff9800',
                    '--warning-dark': '#e65100',
                    '--warning-contrast-text': '#fff',
                    '--info-main': '#0288d1',
                    '--info-light': '#03a9f4',
                    '--info-dark': '#01579b',
                    '--info-contrast-text': '#fff',
                    '--success-main': '#2e7d32',
                    '--success-light': '#4caf50',
                    '--success-dark': '#1b5e20',
                    '--success-contrast-text': '#fff',
                    '--grey-50': '#fafafa',
                    '--grey-100': '#f5f5f5',
                    '--grey-200': '#eeeeee',
                    '--grey-300': '#e0e0e0',
                    '--grey-400': '#bdbdbd',
                    '--grey-500': '#9e9e9e',
                    '--grey-600': '#757575',
                    '--grey-700': '#616161',
                    '--grey-800': '#424242',
                    '--grey-900': '#212121',
                    '--grey-A100': '#f5f5f5',
                    '--grey-A200': '#eeeeee',
                    '--grey-A400': '#bdbdbd',
                    '--grey-A700': '#616161',
                    '--link': 'var(--secondary-dark)',
                },
            },
        },
        // Custom the main container
        MuiContainer: {
            styleOverrides: {
                root: {
                    // border: '1px solid var(--secondary-main)',
                    marginTop: '1rem',
                    padding: '1rem',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // Remove shadow globally for all AppBars
                },
            },
        },
        // Data grid
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    //backgroundColor: 'red',
                    '&:hover, &.Mui-hovered': {
                        cursor: 'pointer',
                    },
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: 'var(--primary-main)',
                        cursor: 'pointer',
                    },
                },
            },
        },
        // Dialog
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--white)',
                    color: 'var(--white)',
                    userSelect: 'none',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--grey-100)',
                    padding: 0,
                },
            },
        },
        // Link
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'var(--link)',
                    '&:hover': {
                        color: 'var(--primary-dark)',
                    },
                    '&:active': {
                        color: 'var(--primary-main)',
                    },
                    '&:focus': {
                        color: 'var(--primary-dark)',
                    },
                },
            },
        },
        // Button styles
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    background: 'var(--primary-main)',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                    },
                    '&:focus': {
                        boxShadow: '0 0 0 0.2rem var(--secondary-light)',
                    },
                },
            },
        },
        // Fab button
        MuiFab: {
            styleOverrides: {
                root: {
                    background: 'var(--primary-main)',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                    },
                    '&:focus': {
                        boxShadow: '0 0 0 0.2rem var(--secondary-light)',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--white)',
                    paddingBottom: 0,
                    paddingTop: 0,
                },
            },
        },
        // Form
        MuiFormControl: {
            styleOverrides: {
                root: {
                    paddingBottom: '1rem',
                    '&:focus, &.Mui-focused': {},
                },
            },
        },
        // Focus state
        MuiFormLabel: {
            styleOverrides: {
                root: {},
            },
        },
        // Focus state
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    '&.MuiInputLabel-root.MuiInputLabel-shrink': {
                        lineHeight: 1.475,
                    },
                    '&:focus, &.Mui-focused': {
                        color: 'var(--secondary-main)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    backgroundColor: 'var(--white)',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--secondary-main)', // Change the global focus border color
                    },
                },
                input: {
                    height: '1rem',
                    '&:focus, &.Mui-focused': {},
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    backgroundColor: 'var(--white)',
                    '&:focus, &.Mui-focused': {},
                },
                input: {
                    '&:-webkit-autofill': {
                        boxShadow: '0 0 0 100px white inset', // Override background color (remove yellow autofill)
                        '-webkit-box-shadow': '0 0 0 100px white inset', // For older WebKit versions
                        '-webkit-text-fill-color': 'black', // Override autofill text color
                        transition: 'background-color 5000s ease-in-out 0s', // Prevent flashing on focus
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    padding: 0,
                    backgroundColor: 'var(--white)',
                    '&:focus, &.Mui-focused': {},
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    zIndex: 1500, // Set z-index to be above everything else
                    position: 'relative', // Ensure it's positioned above other content
                    borderRadius: 0,
                },
            },
        },
        // Data grid
        /*MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'primary.light',
                    '&.Mui-checked': {
                        color: 'white',
                    },
                },
            },
        },*/
    },

    // Breakpoints reference
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    // Typography
    typography: {
        htmlFontSize: 16,
        fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },

    palette: {
        primary: {
            light: '#4A757D',
            main: '#1D535C',
            dark: '#17424A',
            contrastText: '#fff',
        },
        secondary: {
            light: '#71d7d0',
            main: '#4ECDC4',
            dark: '#32b1a8',
            contrastText: '#000',
        },
    },

    customSx: {
        // TabList styles
        globalFormTabList: {
            backgroundColor: 'var(--white)',
            zIndex: (theme: any) => theme.zIndex.modal + 1,
            position: 'fixed',
            width: '100%',
            top: '56px',
            button: {
                fontSize: '12px',
            },
        },
        globalFormTabListForProfile: {
            backgroundColor: 'var(--white)',
            zIndex: (theme: any) => theme.zIndex.modal + 1,
            position: 'fixed',
            width: '100%',
            top: '204px',
            button: {
                fontSize: '12px',
            },
        },
        globalFormTab: (isVisible: boolean) => ({
            display: isVisible ? 'block' : 'none',
            position: 'relative',
            padding: 0,
            top: '72px',
        }),
    },
})

/*const ListItemText = styled(MuiListItemText)({
    '& .MuiListItemText-primary': {
      color: 'orange',
    },
    '& .MuiListItemText-secondary': {
      color: 'gray',
    },
  });*/

export interface DivFlexProps {
    variant?: '' | 'desktop'
}

export const DivFlex = styled('div', {
    // Configure which props should be forwarded on DOM
    shouldForwardProp: prop => prop !== 'variant',
    /*name: 'DivFlex',
    slot: 'Root',*/
    // We are specifying here how the styleOverrides are being applied based on props
    /*overridesResolver: (props, styles) => [
        styles.root
    ],*/
    name: 'MuiDivFlex',
    slot: 'Root',
    skipVariantsResolver: true, // disables theme.components[name].variants
    //skipSx: true,
})<DivFlexProps>(({ theme, variant }) => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    flexDirection: variant === '' ? 'column' : 'row',
    padding: '1rem',
    marginTop: '1rem',
    //bgcolor: theme.palette.primary,
    //backgroundColor: 'LIGHTblue',
    borderRadius: 1,
}))

export default eskolaTheme
