import './_StudentFormDetails.scss'
import { IStudent } from '../../../IStudent'
import UFTextField from '../../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../../Common/UseForm/UFSelect/UFSelect'
import UFMultiSelect from '../../../../../../Common/UseForm/UFMultiSelect/UFMultiSelect'
import UFDatePicker from '../../../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import UFCheckBox from '../../../../../../Common/UseForm/UFCheckBox/UFCheckBox'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { useState } from 'react'
import CustomAvatar from '../../../../../../Common/CustomAvatar/CustomAvatar'
import { EInputType } from '../../../../../../Common/Interfaces/IInputProps'

import { useAuth } from '../../../../../../../contexts/AuthContext'

interface StudentFormDetailsProps {
    item: IStudent
    control: any
    setValue: any
    disabled: boolean
    onImageUpdated: (filename: string) => void
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        width: '100%',
        marginTop: '1rem',
    })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const StudentFormDetails = (props: StudentFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    const [expanded, setExpanded] = useState<string | false>('')
    //const [session] = useSessionContext()
    const { user } = useAuth()
    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    const handlImageUpdated = (filename: string) => {
        props.onImageUpdated(filename)
    }
    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">
                <CustomAvatar
                    itemType="images/student"
                    keyName="StudentID"
                    item={item}
                    onImageUpdated={handlImageUpdated}
                    sizeHeight={200}
                    sizeWidth={200}
                />

                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Name"
                    label={t('Name')}
                    required={t('Empty field!')}
                    disabled={disabled}
                />
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Surname1"
                    label={t('Surname1')}
                    required={t('Empty field!')}
                    disabled={disabled}
                />
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Surname2"
                    label={t('Surname2')}
                    required={t('Empty field!')}
                    disabled={disabled}
                />

                <UFDatePicker name="BirthDate" control={control} label={t('BirthDate')} disabled={disabled} />

                <Accordion expanded={expanded === 'panel0'} onChange={handleChangeAccordion('panel0')}>
                    <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
                        {t('School Details')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <UFSelect
                            setValue={setValue}
                            name="OrganizationID"
                            control={control}
                            label={t('Organization')}
                            idColName="OrganizationID"
                            nameColName="Name"
                            url={
                                user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()
                            }
                            disabled={user!.isSuperUser ? disabled : true}
                            required={t('Required value')}
                            value={user!.OrganizationID.toString()}
                        />
                        <UFSelect
                            setValue={setValue}
                            name="LevelID"
                            control={control}
                            label={t('Level')}
                            idColName="LevelID"
                            nameColName="Name"
                            url="levels"
                            disabled={disabled}
                            required={t('Required value')}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        {t('Contact Details')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="ContactName1"
                            label={t('ContactName1')}
                            disabled={disabled}
                        />
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="ContactNumber1"
                            label={t('ContactNumber1')}
                            disabled={disabled}
                        />
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="ContactEmail1"
                            label={t('ContactEmail1')}
                            disabled={disabled}
                            inputType={EInputType.Email}
                        />
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="ContactName2"
                            label={t('ContactName2')}
                            disabled={disabled}
                        />
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="ContactNumber2"
                            label={t('ContactNumber2')}
                            disabled={disabled}
                        />
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="ContactEmail2"
                            label={t('ContactEmail2')}
                            disabled={disabled}
                            inputType={EInputType.Email}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        {t('Special Requirements')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <UFCheckBox
                            name="SpecialReqs"
                            control={control}
                            label={t('Special Requirements')}
                            disabled={disabled}
                        />
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="Note"
                            label={t('Note')}
                            disabled={disabled}
                        />
                        <UFMultiSelect
                            name="Allergies"
                            control={control}
                            setValue={setValue}
                            label={t('Allergies')}
                            idColName="AllergyID"
                            nameColName="Name"
                            url="allergies"
                            parentApi={'students/' + item.StudentID + '/allergies'}
                            disabled={disabled}
                            startSelectedItems={[]}
                        />
                    </AccordionDetails>
                </Accordion>
            </DivFlex>
        </div>
    )
}

export default StudentFormDetails
